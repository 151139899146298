import { Component, OnInit, inject, signal } from "@angular/core";
import { TooltipDirective } from "../../../../shared/components/tooltip/tooltip.directive";
import { AppSvgIconComponent } from "../../../../shared/components/app-svg-icon/app-svg-icon.component";
import { ButtonComponent } from "../../../../shared/components/button/button.component";
import { OutlineTextInputComponent } from "../../../../shared/inputs/outline-text-input/outline-text-input/outline-text-input.component";
import { State } from "../../../../shared/base/base-state";
import { BaseComponent } from "../../../../shared/base/base-component";
import { CommonModule } from "@angular/common";
import {
  MatTab,
  MatTabChangeEvent,
  MatTabGroup,
  MatTabLabel,
} from "@angular/material/tabs";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { ReactiveFormsModule } from "@angular/forms";
import { SendDriverApplicationComponent } from "../../overlays/send-driver-application/send-driver-application.component";
import { Constants } from "../../../../core/constants/constants";
import { BaseTableComponent } from "../../../../shared/components/_base/base-table/base-table.component";
import { ContextMenuIconComponent } from "../../../../shared/components/context-menu-icon/context-menu-icon.component";
import { DriverApplicationService } from "../../../../core/services/driver-application/driver-application.service";
import type { GetDriverListResponse } from "../../../../core/api/model";

@Component({
  selector: "app-drivers-list",
  standalone: true,
  imports: [
    TooltipDirective,
    AppSvgIconComponent,
    ButtonComponent,
    OutlineTextInputComponent,
    CommonModule,
    MatTabGroup,
    MatTab,
    ReactiveFormsModule,
    MatTabLabel,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatDialogModule,
    SendDriverApplicationComponent,
    BaseTableComponent,
    ContextMenuIconComponent,
  ],
  templateUrl: "./drivers-list.component.html",
  styleUrl: "./drivers-list.component.scss",
})
export class DriversListComponent extends BaseComponent implements OnInit {
  dialog = inject(MatDialog);
  driverApplicationService = inject(DriverApplicationService);
  driverListState = new State<GetDriverListResponse>();

  steps = signal<DriverListStep[]>([
    { title: "Active", type: "active" },
    { title: "Driver at Risk", type: "driver-at-risk" },
    { title: "Pending", type: "pending" },
    { title: "Rejected", type: "rejected" },
    { title: "Terminated", type: "terminated" },
  ]);

  activeStep = signal<DriverListStep>(this.steps()[0]);
  activeStepIndex = signal(0);


  ngOnInit(): void {
    this.getDriverList();
  }

  onSendDriverApplicationClicked() {
    this.dialog.open(SendDriverApplicationComponent, {
      ...Constants.defaultDialogConfig,
    });
  }

  onTabChange($event: MatTabChangeEvent) {
    this.activeStep.set(this.steps()[$event.index]);
    this.activeStepIndex.set($event.index);
  }

  getDriverList() {
    this.executeRequest<GetDriverListResponse>({
      state: this.driverListState,
      request: this.driverApplicationService.getDriverList(),
    });
  }
}

export interface DriverListStep {
  title: string;
  type: "active" | "driver-at-risk" | "pending" | "rejected" | "terminated";
}
