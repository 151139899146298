export class URLs {

  // Auth
  static signUp = '/company/auth/sign-up';
  static login = '/company/auth/login';
  static setupPassword = '/company/auth/setup-password';
  static forgotPassword = '/company/auth/forgot-password';
  static resetPassword = '/company/auth/reset-password';
  static setupCompanyProfile = '/company/complete-profile';

  // Dashboard
  static dashboard = '/company/dashboard';

  // Driver
  static sendDriverApplication = '/company/driver-applications/send';
  static getDriverApplication = '/company/driver-applications';
  static getDriverList = '/company/drivers';

  // Driver Application
  static getDriverApplicationInfo = '/company/driver-applications/info';
  static getDriverApplicationGeneralInfo(driverApplicationId: string) {
    return `/company/driver-application-generals/${driverApplicationId}`;
  };
  static getCompleteApplicationInfo(driverApplicationId: string) {
    return `/company/get-complete-driver-applications/${driverApplicationId}`;
  };

  static saveApplicantGeneralInfo = '/company/driver-application-generals';
  static getApplicantResidencies = '/company/driver-application-residencies';
  static getApplicantLicenses = '/company/driver-application-licenses';
  static saveApplicantLicenses = '/company/driver-application-licenses';
  static getApplicantExperiences = '/company/driver-application-experiences';
  static saveApplicantResidencies = '/company/driver-application-residencies';
  static saveApplicantExperiences = '/company/driver-application-experiences';
  static getApplicantAccidents = '/company/driver-application-accidents';
  static saveApplicantAccidents = '/company/driver-application-accidents';
  static saveApplicantEmploymentHistory= '/company/driver-application-employment-history';
  static saveApplicantEducationHistory = '/company/driver-application-education';
  static getApplicantEducationHistory = '/company/driver-application-education';
  static saveCompleteApplication='/company/complete-driver-applications';

  // Company
  static getCompanyTypes = '/common/company/types';
  static getCountries = '/common/countries';
  static getStatesList = '/common/states';
  static getBusinessType = '/common/business-types';
  static getBusinessSubType = '/common/business-sub-types';
  static getCompanyInfo = '/company/info';


}
