import {Component, inject, OnInit} from '@angular/core';
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {MatDialog} from "@angular/material/dialog";
import {AppSvgIconComponent} from "../../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {BaseDialogComponent} from "../../../../../shared/components/_base/base-dialog/base-dialog.component";
import {
  OutlineTextInputComponent
} from "../../../../../shared/inputs/outline-text-input/outline-text-input/outline-text-input.component";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {State} from "../../../../../shared/base/base-state";

@Component({
  selector: 'app-driver-application-status-update',
  standalone: true,
  imports: [
    ButtonComponent,
    AppSvgIconComponent,
    BaseDialogComponent,
    OutlineTextInputComponent,
    ReactiveFormsModule,
    FormsModule
  ],
  templateUrl: './driver-application-status-update.component.html',
  styleUrl: './driver-application-status-update.component.scss'
})
export class DriverApplicationStatusUpdateComponent implements OnInit{
  readonly dialog = inject(MatDialog);
  formBuilder = inject(FormBuilder);
  changeDriverApplicationState= new State<any>();
  changeDriverApplicationStatusForm!: FormGroup;

  ngOnInit() {
    this.initForm();
  }

  initForm()
  {
    this.changeDriverApplicationStatusForm= this.formBuilder.group(
      {
        statusChange: ["", Validators.required],
      }
    );
  }

  closeDialog() {
    this.dialog.closeAll();
  }
}
