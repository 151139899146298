<div class="flex justify-center container mx-auto h-full w-full p-4">
  <div class="flex flex-col bg-white w-full rounded-md px-4">
    <div class="grow overflow-auto p-4">
      <div class="flex flex-col gap-3">
        <p class="text-h6 text-info-500">Accident History</p>
        <p class="text-caption text-neutral-500">Please provide us all your accident records for past 3 years</p>
        <form [formGroup]="accidentsForm">
          <div class="flex items-center gap-3 w-full">
            <span class="text-body2">Have you ever been involved in an accident?</span>
            <app-radio-button formControlName="causedDrivingAccident" groupName="causedDrivingAccident" title="Yes"
                              [value]="true"></app-radio-button>
            <app-radio-button formControlName="causedDrivingAccident" groupName="causedDrivingAccident" title="No"
                              [value]="false"></app-radio-button>
          </div>

          @if (accidentsForm.controls['causedDrivingAccident'].value) {
            <div formArrayName="accidents">
              @for (group of accidentsFormArray.controls; track group; let i = $index; let isLast = $last; let isFirst = $first) {
                <div class="driving-experience-form flex flex-col gap-3 my-4" [formGroupName]="i">
                  <span class="text-body2 text-info-500">ADDITIONAL ACCIDENT DETAILS</span>
                  <div class="grid lg:grid-cols-2 gap-4">
                    <app-outline-text-input formControlName="accidentDate" [label]="'Accident Date'" mask="00/00/0000"
                                            placeholder="Ex: 10/24/2019" class="w-full"
                                            [fullWidth]="true"></app-outline-text-input>
                    <app-outline-text-input formControlName="natureOfAccident" [label]="'Nature of Accident'"
                                            placeholder="Ex: Head-on, Rear-end, upset, etc." class="w-full"
                                            [fullWidth]="true"></app-outline-text-input>
                  </div>
                  <div class="grid grid-cols-2 gap-4">
                    <app-outline-text-input formControlName="fatalities" [label]="'Fatalities #'" type="number"
                                            placeholder="Ex: 1" class="w-full"
                                            [fullWidth]="true"></app-outline-text-input>
                    <app-outline-text-input formControlName="injuries" [label]="'Injuries #'" type="number"
                                            placeholder="Ex: 4" class="w-full"
                                            [fullWidth]="true"></app-outline-text-input>
                    <div class="flex items-center gap-3 w-full">
                      <span class="text-body2">Chemical Spills</span>
                      <app-radio-button formControlName="hasChemicalSpills" [groupName]="'hasChemicalSpills' + i" title="Yes"
                                        [value]="true"></app-radio-button>
                      <app-radio-button formControlName="hasChemicalSpills" [groupName]="'hasChemicalSpills' + i" title="No"
                                        [value]="false"></app-radio-button>
                    </div>
                    @if (!isFirst) {
                      <div class="flex justify-end">
                        <app-button appearance="outline" outlineColor="border-error-500"
                                    textButtonColor="text-error-500" (click)="onDeleteClicked(i)">Delete
                        </app-button>
                      </div>
                    }
                  </div>
                </div>
                @if (!isLast) {
                  <div class="w-full h-[1px] bg-neutral-100 my-8"></div>
                }
              }
              <div class="flex flex-col gap-4 my-3">
                <app-button iconSrc="assets/icons/plus.svg" appearance="outline" [fullWidth]="true"
                            (click)="onAddMoreAccidentsClicked()">ADD MORE ACCIDENTS
                </app-button>
              </div>
            </div>
          }
        </form>
      </div>
    </div>
    <div class="flex justify-between border-t border-neutral-200 px-4 py-4">
      <app-button appearance="outline" (click)="onPreviousClicked()">Previous</app-button>
      <app-button type="submit" (click)="onSaveAndNextClicked()" [loading]="saveAccidentsState.loading()">Save & Next
      </app-button>
    </div>
  </div>
</div>
