<app-base-input [title]="title()" [fullWidth]="fullWidth()" (keydown)="handleKeydown($event)"
                [isRequiredField]="hasRequiredValidator()">
  <div #dropdownButton input class="relative inline-block text-left min-w-52">
    <div (clickOutside)="onClickOutside()">
      <button (click)="toggleDropdown()" type="button" tabindex="0"
              class="h-[46px] inline-flex items-center w-full px-4 py-2 text-sm justify-between font-medium text-gray-700 bg-white border-none outline-none border-gray-300 ring-1 focus:ring-2 ring-neutral-300 focus:ring-primary-500 rounded-md shadow-1 focus:border-none focus:outline-none transition-all duration-300">
        <span class="text-body1 text-left overflow-ellipsis"
              [ngClass]="(formControl.value != null || selectedItem() != null) ? 'text-black' : 'text-gray-400'">{{ getProperty(selectedItem()) || getProperty(getSelectedItem()) || placeholder() }}</span>
        <svg class="-mr-1 h-5 w-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path d="M7.41 8.57996L12 13.17L16.59 8.57996L18 9.99996L12 16L6 9.99996L7.41 8.57996Z" fill="currentColor"/>
        </svg>
      </button>
    </div>
    @if (isOpen()) {
      <div
        [ngClass]="{ 'mt-2': !dropUp(), 'mb-2 bottom-12': dropUp() }"
        class="z-50 absolute w-full origin-top-right divide-y divide-gray-100 rounded-md bg-neutral-50 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-y-scroll scrollbar scrollbar-thumb-gray-500 scrollbar-track-gray-300 border"
        role="menu" aria-orientation="vertical" aria-labelledby="options-menu" tabindex="-1">
        <div #dropdownList class="py-1" role="none">
          @for (item of items(); track item; let i = $index) {
            <span class="text-gray-700 block px-4 py-3 text-sm hover:bg-neutral-100 cursor-pointer"
                  [ngClass]="(highlightedIndex() == i) ? 'bg-neutral-200' : 'bg-neutral-50'" role="menuitem"
                  tabindex="-1" (click)="optionSelected(item)">{{ getDisplayString(item) }}</span>
          } @empty {
            <span class="text-gray-400 block px-4 py-4 text-sm select-none" role="menuitem" tabindex="-1">No options available</span>
          }
        </div>
      </div>
    }
  </div>
</app-base-input>

@if (formControl.errors && hasErrors) {
  <div class="mb-1">
    <span class="text-caption text-red-500">{{ formControl.errors | humanizeFormMessages: errorMessages() }}</span>
  </div>
} @else {
  @if (showErrorSpace()) {
    <div class="mb-1">
      <span class="text-caption text-transparent">.</span>
    </div>
  }
}
