import { Injectable, signal } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LayoutService {
  currentMenu = signal<{ label: string; iconPath: string }>({
    label: "Dashboard",
    iconPath: "assets/icons/dashboard.svg",
  });

  private _sidebarExpanded = signal(true);

  get sidebarExpanded() {
    return this._sidebarExpanded();
  }

  public toggleSidebar() {
    this._sidebarExpanded.update((curr) => !curr);
  }

  setCurrentMenu(menu: { label: string; iconPath: string }) {
    this.currentMenu.set(menu);
  }
}
