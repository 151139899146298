<div class="container mx-auto">

  <div class="fixed top-5 right-5">
    <app-button (click)="onValidate()">Validate</app-button>
  </div>
  <form class="flex flex-col gap-10" [formGroup]="form">
    <app-outline-text-input formControlName="q"></app-outline-text-input>
    <app-outline-text-input formControlName="w"></app-outline-text-input>
    <app-outline-text-input formControlName="e"></app-outline-text-input>
    <app-outline-text-input formControlName="r"></app-outline-text-input>
    <app-outline-text-input formControlName="t"></app-outline-text-input>
    <app-outline-text-input formControlName="y"></app-outline-text-input>
    <app-outline-text-input formControlName="u"></app-outline-text-input>
    <app-outline-text-input formControlName="i"></app-outline-text-input>
    <app-outline-text-input formControlName="o"></app-outline-text-input>
    <app-outline-text-input formControlName="p"></app-outline-text-input>
    <app-outline-text-input formControlName="a"></app-outline-text-input>
    <app-outline-text-input formControlName="s"></app-outline-text-input>
    <app-outline-text-input formControlName="d"></app-outline-text-input>
    <app-outline-text-input formControlName="f"></app-outline-text-input>
    <app-outline-text-input formControlName="g"></app-outline-text-input>
    <app-outline-text-input formControlName="h"></app-outline-text-input>
    <app-outline-text-input formControlName="j"></app-outline-text-input>
    <app-outline-text-input formControlName="k"></app-outline-text-input>
    <app-outline-text-input formControlName="l"></app-outline-text-input>
  </form>

    <p class="py-20">Hello</p>
    <p class="py-20">Hello</p>
    <p class="py-20">Hello</p>
    <p class="py-20">Hello</p>
    <p class="py-20">Hello</p>
    <p class="py-20">Hello</p>
    <app-dropdown class="w-2" [display]="'name'" [value]="''" [(ngModel)]="selectedCountry"
                  [items]="countries()" (valueChanged)="changeed($event)"></app-dropdown>
    <p class="py-20">Hello</p>
    <p class="py-20">Hello</p>
    <div
            class="w-[800px] relative rounded-xl overflow-auto before:box-border before:border-0 before:border-solid before:border-gray-200"
            tailscan-temporary-conversion-root-element="true" data-tailscan-convert-id="root"
            data-original-classes="relative rounded-xl overflow-auto before:box-border before:border-0 before:border-solid before:border-gray-200"
            data-new-classes="rounded-xl overflow-auto relative before:border-0 before:border-solid before:border-gray-200">
        <div class="mx-4 bg-white dark:bg-slate-800 shadow-xl overflow-hidden"
             data-tailscan-convert-id="fbmvvos7dehwxe6ltuytv">
            <div class="overflow-scroll grid grid-cols-[70px,repeat(7,150px)] grid-rows-[auto,repeat(16,50px)] max-h-[350px]"
                 data-tailscan-convert-id="v45h4b4t1ca5v3oa6ovxh">
                <!-- Calendar frame -->
                <div
                        class="row-start-[1] col-start-[1] sticky top-0 z-10 bg-white dark:bg-gradient-to-b dark:from-slate-600 dark:to-slate-700 border-slate-100 dark:border-black/10 bg-clip-padding text-slate-900 dark:text-slate-200 border-b text-sm font-medium py-2"
                        data-tailscan-convert-id="30t382c2lp6k37evb7klxk"></div>
                <div
                        class="row-start-[1] col-start-[2] sticky top-0 z-10 bg-white dark:bg-gradient-to-b dark:from-slate-600 dark:to-slate-700 border-slate-100 dark:border-black/10 bg-clip-padding text-slate-900 dark:text-slate-200 border-b text-sm font-medium py-2 text-center"
                        data-tailscan-convert-id="l2tsesembdgx3k0j01kut">Sun
                </div>
                <div
                        class="row-start-[1] col-start-[3] sticky top-0 z-10 bg-white dark:bg-gradient-to-b dark:from-slate-600 dark:to-slate-700 border-slate-100 dark:border-black/10 bg-clip-padding text-slate-900 dark:text-slate-200 border-b text-sm font-medium py-2 text-center"
                        data-tailscan-convert-id="23xyl31069ugepkgli9lhl">Mon
                </div>
                <div
                        class="row-start-[1] col-start-[4] sticky top-0 z-10 bg-white dark:bg-gradient-to-b dark:from-slate-600 dark:to-slate-700 border-slate-100 dark:border-black/10 bg-clip-padding text-slate-900 dark:text-slate-200 border-b text-sm font-medium py-2 text-center"
                        data-tailscan-convert-id="ha4962t7930rocnwt9h2z">Tue
                </div>
                <div
                        class="row-start-[1] col-start-[5] sticky top-0 z-10 bg-white dark:bg-gradient-to-b dark:from-slate-600 dark:to-slate-700 border-slate-100 dark:border-black/10 bg-clip-padding text-slate-900 dark:text-slate-200 border-b text-sm font-medium py-2 text-center"
                        data-tailscan-convert-id="dhg27ro0ha18p67aasozy">Wed
                </div>
                <div
                        class="row-start-[1] col-start-[6] sticky top-0 z-10 bg-white dark:bg-gradient-to-b dark:from-slate-600 dark:to-slate-700 border-slate-100 dark:border-black/10 bg-clip-padding text-slate-900 dark:text-slate-200 border-b text-sm font-medium py-2 text-center"
                        data-tailscan-convert-id="s6rm8kytwty903r6vytd">Thu
                </div>
                <div
                        class="row-start-[1] col-start-[7] sticky top-0 z-10 bg-white dark:bg-gradient-to-b dark:from-slate-600 dark:to-slate-700 border-slate-100 dark:border-black/10 bg-clip-padding text-slate-900 dark:text-slate-200 border-b text-sm font-medium py-2 text-center"
                        data-tailscan-convert-id="7pjg3jwdy9xuuzhs73vs8q">Fri
                </div>
                <div
                        class="row-start-[1] col-start-[8] sticky top-0 z-10 bg-white dark:bg-gradient-to-b dark:from-slate-600 dark:to-slate-700 border-slate-100 dark:border-black/10 bg-clip-padding text-slate-900 dark:text-slate-200 border-b text-sm font-medium py-2 text-center"
                        data-tailscan-convert-id="f0dqzwcgj4exuf31r7bzym">Sat
                </div>
                <div
                        class="row-start-[2] col-start-[1] border-slate-100 dark:border-slate-200/5 border-r text-xs p-1.5 text-right text-slate-400 uppercase sticky left-0 bg-white dark:bg-slate-800 font-medium"
                        data-tailscan-convert-id="r2g4nd3imsnqvnoyrr5bik">5 AM
                </div>
                <div class="row-start-[2] col-start-[2] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="mygxnat98yddx3xdu1lajm"></div>
                <div class="row-start-[2] col-start-[3] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="3u4toodkwqz9hr26yxub4"></div>
                <div class="row-start-[2] col-start-[4] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="en4aqrnaoavvneiuu61x6p"></div>
                <div class="row-start-[2] col-start-[5] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="a9kfcuy4vqgwbpcvp5ylq"></div>
                <div class="row-start-[2] col-start-[6] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="ygr94bfn2u3l23971bi9h"></div>
                <div class="row-start-[2] col-start-[7] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="46714t73hfr484u77evfmq"></div>
                <div class="row-start-[2] col-start-[8] border-slate-100 dark:border-slate-200/5 border-b"
                     data-tailscan-convert-id="urj7a6koolo8xivboaz7"></div>
                <div
                        class="row-start-[3] col-start-[1] border-slate-100 dark:border-slate-200/5 border-r text-xs p-1.5 text-right text-slate-400 uppercase sticky left-0 bg-white dark:bg-slate-800 font-medium"
                        data-tailscan-convert-id="912lt60vz0lqxiwnbtpzg">6 AM
                </div>
                <div class="row-start-[3] col-start-[2] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="0ssq38p9wq9ej9ugfx2p9d"></div>
                <div class="row-start-[3] col-start-[3] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="80tp1zp524l9gkvuwgwdxo"></div>
                <div class="row-start-[3] col-start-[4] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="ouzm6tnkiooxn8pj1uzfa"></div>
                <div class="row-start-[3] col-start-[5] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="bn4h98c64xtg8mlxg2kiyk"></div>
                <div class="row-start-[3] col-start-[6] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="wvw1vrzlftllccv7w42p"></div>
                <div class="row-start-[3] col-start-[7] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="zuk75t0wgs97y0z624a0kv"></div>
                <div class="row-start-[3] col-start-[8] border-slate-100 dark:border-slate-200/5 border-b"
                     data-tailscan-convert-id="u4ujytpqtps7b17nnjciao"></div>
                <div
                        class="row-start-[4] col-start-[1] border-slate-100 dark:border-slate-200/5 border-r text-xs p-1.5 text-right text-slate-400 uppercase sticky left-0 bg-white dark:bg-slate-800 font-medium"
                        data-tailscan-convert-id="zf207ipszvcatjvbmiwyyp">7 AM
                </div>
                <div class="row-start-[4] col-start-[2] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="3hxd0ahohnxlmhhhu8swjr"></div>
                <div class="row-start-[4] col-start-[3] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="95cndeityyeov5bjldva5"></div>
                <div class="row-start-[4] col-start-[4] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="1w3bbv235yy3l5c3vscrt9"></div>
                <div class="row-start-[4] col-start-[5] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="zja5cdzz0pazk94ztchy"></div>
                <div class="row-start-[4] col-start-[6] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="l94d11avqz8arexetqt3jw"></div>
                <div class="row-start-[4] col-start-[7] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="t2t3rvlbrsd6a3txeg2e9n"></div>
                <div class="row-start-[4] col-start-[8] border-slate-100 dark:border-slate-200/5 border-b"
                     data-tailscan-convert-id="8zin9nwyfsx33pspbr2t46"></div>
                <div
                        class="row-start-[5] col-start-[1] border-slate-100 dark:border-slate-200/5 border-r text-xs p-1.5 text-right text-slate-400 uppercase sticky left-0 bg-white dark:bg-slate-800 font-medium"
                        data-tailscan-convert-id="cb3y7dhnnbj0hshzr96i">8 AM
                </div>
                <div class="row-start-[5] col-start-[2] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="j78mvgjccepn5lcijcbqm"></div>
                <div class="row-start-[5] col-start-[3] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="rfbddw81s6p344wsgqqwgs"></div>
                <div class="row-start-[5] col-start-[4] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="s7k6hw1goicrbuzcy1akp8"></div>
                <div class="row-start-[5] col-start-[5] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="enzt280jxh93bhpeutgsda"></div>
                <div class="row-start-[5] col-start-[6] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="i8moevr2mi9mdr3750lxa"></div>
                <div class="row-start-[5] col-start-[7] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="t3v497oz6u126ih6zw198e"></div>
                <div class="row-start-[5] col-start-[8] border-slate-100 dark:border-slate-200/5 border-b"
                     data-tailscan-convert-id="2j8gqd880on5r0cu8ye639"></div>
                <div
                        class="row-start-[6] col-start-[1] border-slate-100 dark:border-slate-200/5 border-r text-xs p-1.5 text-right text-slate-400 uppercase sticky left-0 bg-white dark:bg-slate-800 font-medium"
                        data-tailscan-convert-id="k3xkxmaw84e4xy00doetc7">9 AM
                </div>
                <div class="row-start-[6] col-start-[2] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="5g2wix46368h842xqnqq4"></div>
                <div class="row-start-[6] col-start-[3] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="lmh82t1tfm96d4d2org6n"></div>
                <div class="row-start-[6] col-start-[4] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="3cblltbf15wbh9orr38e6k"></div>
                <div class="row-start-[6] col-start-[5] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="nyxf5g3nzaxp0lillwhpl"></div>
                <div class="row-start-[6] col-start-[6] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="s7kdsj12yglu3yrngwjtgg"></div>
                <div class="row-start-[6] col-start-[7] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="9ecq1wml9bbrgw6rgwqmcf"></div>
                <div class="row-start-[6] col-start-[8] border-slate-100 dark:border-slate-200/5 border-b"
                     data-tailscan-convert-id="pv115j0dgc5q69n4lvxiu"></div>
                <div
                        class="row-start-[7] col-start-[1] border-slate-100 dark:border-slate-200/5 border-r text-xs p-1.5 text-right text-slate-400 uppercase sticky left-0 bg-white dark:bg-slate-800 font-medium"
                        data-tailscan-convert-id="1fyo2locpxzczexbyhxu9v">10 AM
                </div>
                <div class="row-start-[7] col-start-[2] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="ocmme002avef5zcsqnrh34"></div>
                <div class="row-start-[7] col-start-[3] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="c6d8hji4w7dsohxfa23h38"></div>
                <div class="row-start-[7] col-start-[4] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="x72trofkvrrubexc83wf3h"></div>
                <div class="row-start-[7] col-start-[5] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="nf3o3wxkoyyq5owbjagm"></div>
                <div class="row-start-[7] col-start-[6] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="ezvm78xsdqdt8090htfrlb"></div>
                <div class="row-start-[7] col-start-[7] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="fd72z9se9e6h8upvykufwd"></div>
                <div class="row-start-[7] col-start-[8] border-slate-100 dark:border-slate-200/5 border-b"
                     data-tailscan-convert-id="qj9a27e2t1sjoeey74hp2h"></div>
                <div
                        class="row-start-[8] col-start-[1] border-slate-100 dark:border-slate-200/5 border-r text-xs p-1.5 text-right text-slate-400 uppercase sticky left-0 bg-white dark:bg-slate-800 font-medium"
                        data-tailscan-convert-id="umrbx5clvbpswcn8hjz52d">11 AM
                </div>
                <div class="row-start-[8] col-start-[2] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="gwwg4s46ezn1nts76xlwxv"></div>
                <div class="row-start-[8] col-start-[3] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="penlc00mpps4tzdoa638"></div>
                <div class="row-start-[8] col-start-[4] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="3qeaqco0hp7wfrgzrb600f"></div>
                <div class="row-start-[8] col-start-[5] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="ce60pbmz1hhcbvd791wiyt"></div>
                <div class="row-start-[8] col-start-[6] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="kfdafmj3l4oassof86qlk"></div>
                <div class="row-start-[8] col-start-[7] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="g7guzk7sw26maihlyslvb"></div>
                <div class="row-start-[8] col-start-[8] border-slate-100 dark:border-slate-200/5 border-b"
                     data-tailscan-convert-id="ubtugt3uv2o0qc6hwve0ag"></div>
                <div
                        class="row-start-[9] col-start-[1] border-slate-100 dark:border-slate-200/5 border-r text-xs p-1.5 text-right text-slate-400 uppercase sticky left-0 bg-white dark:bg-slate-800 font-medium"
                        data-tailscan-convert-id="i2chhtt3zm74ujdwh7nq5">12 PM
                </div>
                <div class="row-start-[9] col-start-[2] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="7gbyj97nng6t13dlmvaa3"></div>
                <div class="row-start-[9] col-start-[3] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="gpq3gvvzh89nvw1qmiillh"></div>
                <div class="row-start-[9] col-start-[4] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="276ubfaw40lifb6etwz2sqs"></div>
                <div class="row-start-[9] col-start-[5] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="9dx2yzv82qi9au4q3zrby8"></div>
                <div class="row-start-[9] col-start-[6] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="d55xpmotgmp8okrj2g2bg"></div>
                <div class="row-start-[9] col-start-[7] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="uidhgw683rn1amnjl5flf"></div>
                <div class="row-start-[9] col-start-[8] border-slate-100 dark:border-slate-200/5 border-b"
                     data-tailscan-convert-id="9j06p00sjwa3dg9lol9lwl"></div>
                <div
                        class="row-start-[10] col-start-[1] border-slate-100 dark:border-slate-200/5 border-r text-xs p-1.5 text-right text-slate-400 uppercase sticky left-0 bg-white dark:bg-slate-800 font-medium"
                        data-tailscan-convert-id="1hrj9hkpxmqodw8kyp1w7i">1 PM
                </div>
                <div class="row-start-[10] col-start-[2] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="8fy007wnbx3tcies89153"></div>
                <div class="row-start-[10] col-start-[3] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="6ndj1zxjgmt965jce6fryf"></div>
                <div class="row-start-[10] col-start-[4] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="5c8c8zctmk5adej1sko5ao"></div>
                <div class="row-start-[10] col-start-[5] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="rzdyuzylp4e8c79ydm3vnq"></div>
                <div class="row-start-[10] col-start-[6] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="qzgo2ep3l7dvt9dxumkj1p"></div>
                <div class="row-start-[10] col-start-[7] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="m2xvjdwjbhic8q51x26yuu"></div>
                <div class="row-start-[10] col-start-[8] border-slate-100 dark:border-slate-200/5 border-b"
                     data-tailscan-convert-id="yzg5t8an0qeous4jv4pui"></div>
                <div
                        class="row-start-[11] col-start-[1] border-slate-100 dark:border-slate-200/5 border-r text-xs p-1.5 text-right text-slate-400 uppercase sticky left-0 bg-white dark:bg-slate-800 font-medium"
                        data-tailscan-convert-id="u1yjnk0cv3ga9ypf6523iv">2 PM
                </div>
                <div class="row-start-[11] col-start-[2] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="d6lyav1pvursx67hbof3g"></div>
                <div class="row-start-[11] col-start-[3] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="ctuzo9h7mbmu1jpdpr6wg"></div>
                <div class="row-start-[11] col-start-[4] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="vr4w8oqqajbj1mgjaau5db"></div>
                <div class="row-start-[11] col-start-[5] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="6fxyp3n9fv5ldc005iypz"></div>
                <div class="row-start-[11] col-start-[6] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="g6nl8o9iahul5lwr5qpqjr"></div>
                <div class="row-start-[11] col-start-[7] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="iy7229v662gyhpif4x712"></div>
                <div class="row-start-[11] col-start-[8] border-slate-100 dark:border-slate-200/5 border-b"
                     data-tailscan-convert-id="jhvr5lmuecqkiliprp0y"></div>
                <div
                        class="row-start-[12] col-start-[1] border-slate-100 dark:border-slate-200/5 border-r text-xs p-1.5 text-right text-slate-400 uppercase sticky left-0 bg-white dark:bg-slate-800 font-medium"
                        data-tailscan-convert-id="kfic8uasyhamtrz4l2e9o">3 PM
                </div>
                <div class="row-start-[12] col-start-[2] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="gbvyl5yua3d082trzdz6tbk"></div>
                <div class="row-start-[12] col-start-[3] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="7pel56b6o5p6834di2d7h2"></div>
                <div class="row-start-[12] col-start-[4] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="h1v6l0cihhplwdcdcnvop"></div>
                <div class="row-start-[12] col-start-[5] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="xojy1ta0a9c736ui4176z"></div>
                <div class="row-start-[12] col-start-[6] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="5pr5jqv00maz4decgjmflh"></div>
                <div class="row-start-[12] col-start-[7] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="q3e62p0m486x9c2e189l5"></div>
                <div class="row-start-[12] col-start-[8] border-slate-100 dark:border-slate-200/5 border-b"
                     data-tailscan-convert-id="rcuy1zlkg1dgumotyfdg0n"></div>
                <div
                        class="row-start-[13] col-start-[1] border-slate-100 dark:border-slate-200/5 border-r text-xs p-1.5 text-right text-slate-400 uppercase sticky left-0 bg-white dark:bg-slate-800 font-medium"
                        data-tailscan-convert-id="b16nkm3ht4kl6eprrspzsh">4 PM
                </div>
                <div class="row-start-[13] col-start-[2] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="i897qk4jrlfttxf3wnh4"></div>
                <div class="row-start-[13] col-start-[3] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="yjhhfwop9vbfx3ji6c3ov"></div>
                <div class="row-start-[13] col-start-[4] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="322qtc7wbiturr6dvo20u8"></div>
                <div class="row-start-[13] col-start-[5] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="0c6mkipq7eftx6awdwq1ah"></div>
                <div class="row-start-[13] col-start-[6] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="upr45g8epcamq5nfow0acr"></div>
                <div class="row-start-[13] col-start-[7] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="bvr1hr44awog62fj353zh7"></div>
                <div class="row-start-[13] col-start-[8] border-slate-100 dark:border-slate-200/5 border-b"
                     data-tailscan-convert-id="7hw2blqh0lkha9c0si0ue"></div>
                <div
                        class="row-start-[14] col-start-[1] border-slate-100 dark:border-slate-200/5 border-r text-xs p-1.5 text-right text-slate-400 uppercase sticky left-0 bg-white dark:bg-slate-800 font-medium"
                        data-tailscan-convert-id="hy851lrmi2t9gtvut7fzc">5 PM
                </div>
                <div class="row-start-[14] col-start-[2] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="bgsav7d2tklxqgilud0aui"></div>
                <div class="row-start-[14] col-start-[3] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="a6g1fp6tso7ogl0usyecx"></div>
                <div class="row-start-[14] col-start-[4] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="7brclq9i0oxy3nzpabht8"></div>
                <div class="row-start-[14] col-start-[5] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="cy0di1ugdlvb9gd2a1e3qn"></div>
                <div class="row-start-[14] col-start-[6] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="98ltu9psbx823704znf64p"></div>
                <div class="row-start-[14] col-start-[7] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="lkfchpab6z43jj6bevdgw"></div>
                <div class="row-start-[14] col-start-[8] border-slate-100 dark:border-slate-200/5 border-b"
                     data-tailscan-convert-id="zymrp49uittxk36wnjwxw"></div>
                <div
                        class="row-start-[15] col-start-[1] border-slate-100 dark:border-slate-200/5 border-r text-xs p-1.5 text-right text-slate-400 uppercase sticky left-0 bg-white dark:bg-slate-800 font-medium"
                        data-tailscan-convert-id="fb48a5xo34b4zw9op9b4qv">6 PM
                </div>
                <div class="row-start-[15] col-start-[2] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="pitzoh3funso9hlukxpfb"></div>
                <div class="row-start-[15] col-start-[3] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="iu7u3b5aeq2xp9jzva6fs"></div>
                <div class="row-start-[15] col-start-[4] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="5c91rdpnygjg4dtezo503"></div>
                <div class="row-start-[15] col-start-[5] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="mfbw95fnzopb4qwfjjhk"></div>
                <div class="row-start-[15] col-start-[6] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="maj00lox6iba245zxz51no"></div>
                <div class="row-start-[15] col-start-[7] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="fe2j8ovi7jvm4el0h7q4s"></div>
                <div class="row-start-[15] col-start-[8] border-slate-100 dark:border-slate-200/5 border-b"
                     data-tailscan-convert-id="xa28imbt8teh2l6upm5xb9"></div>
                <div
                        class="row-start-[16] col-start-[1] border-slate-100 dark:border-slate-200/5 border-r text-xs p-1.5 text-right text-slate-400 uppercase sticky left-0 bg-white dark:bg-slate-800 font-medium"
                        data-tailscan-convert-id="vgn59vtuk1e6sobkentjy9">7 PM
                </div>
                <div class="row-start-[16] col-start-[2] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="42fp1f6nsggxfubn3jx5ki"></div>
                <div class="row-start-[16] col-start-[3] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="74nrx55hmv3x6jjql2y1ma"></div>
                <div class="row-start-[16] col-start-[4] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="9lvaw1gjs5ihcr5l5862p7"></div>
                <div class="row-start-[16] col-start-[5] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="1vvvgw8ihwpuvxwoxqi40e"></div>
                <div class="row-start-[16] col-start-[6] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="edeh8mq0t8hixf0w5mviyl"></div>
                <div class="row-start-[16] col-start-[7] border-slate-100 dark:border-slate-200/5 border-b border-r"
                     data-tailscan-convert-id="73bd6aym4hg0dmx0t3a7m3e"></div>
                <div class="row-start-[16] col-start-[8] border-slate-100 dark:border-slate-200/5 border-b"
                     data-tailscan-convert-id="cu1sh3zy8nejbrikxkj457"></div>
                <div
                        class="row-start-[17] col-start-[1] border-slate-100 dark:border-slate-200/5 border-r text-xs p-1.5 text-right text-slate-400 uppercase sticky left-0 bg-white dark:bg-slate-800 font-medium"
                        data-tailscan-convert-id="y2zlr01a4rh26wl06gb2u">8 PM
                </div>
                <div class="row-start-[17] col-start-[2] border-slate-100 dark:border-slate-200/5 border-r"
                     data-tailscan-convert-id="jqlrpszp4dn3f0k785nwc"></div>
                <div class="row-start-[17] col-start-[3] border-slate-100 dark:border-slate-200/5 border-r"
                     data-tailscan-convert-id="9hp91jp3uzagqfl894vjmk"></div>
                <div class="row-start-[17] col-start-[4] border-slate-100 dark:border-slate-200/5 border-r"
                     data-tailscan-convert-id="8s71lnlsnlufrr4qupx7iw"></div>
                <div class="row-start-[17] col-start-[5] border-slate-100 dark:border-slate-200/5 border-r"
                     data-tailscan-convert-id="wbu4qx2jc85dtsnw4tf7v"></div>
                <div class="row-start-[17] col-start-[6] border-slate-100 dark:border-slate-200/5 border-r"
                     data-tailscan-convert-id="sq2jjjjkxj8y653cb2fgt"></div>
                <div class="row-start-[17] col-start-[7] border-slate-100 dark:border-slate-200/5 border-r"
                     data-tailscan-convert-id="gnr5s64esz1rqhth70kdv"></div>
                <div class="row-start-[17] col-start-[8]" data-tailscan-convert-id="m3rbd42a64sztw1ct4sat9"></div>
                <!-- Calendar contents -->
                <div
                        class="row-start-[2] col-start-3 row-span-4 bg-blue-400/20 dark:bg-sky-600/50 border border-blue-700/10 dark:border-sky-500 rounded-lg m-1 p-1 flex flex-col"
                        data-tailscan-convert-id="uio8ia6pdwdocf4zwdwpjk">
        <span class="text-xs text-blue-600 dark:text-sky-100"
              data-tailscan-convert-id="292xli4kww5lav0z60tx3b">5 AM</span>
                    <span class="text-xs font-medium text-blue-600 dark:text-sky-100"
                          data-tailscan-convert-id="jzaet9enmp24ek3lndk1i">Flight to Vancouver</span>
                    <span class="text-xs text-blue-600 dark:text-sky-100"
                          data-tailscan-convert-id="edck7coo4wjxfvdfqb9g3">Toronto YYZ</span>
                </div>
                <div
                        class="row-start-[3] col-start-[4] row-span-4 bg-purple-400/20 dark:bg-fuchsia-600/50 border border-purple-700/10 dark:border-fuchsia-500 rounded-lg m-1 p-1 flex flex-col"
                        data-tailscan-convert-id="s6p99r4lkq7kudfzb9u8ca">
        <span class="text-xs text-purple-600 dark:text-fuchsia-100"
              data-tailscan-convert-id="rc4w9h0s2en97255j9se">6 AM</span>
                    <span class="text-xs font-medium text-purple-600 dark:text-fuchsia-100"
                          data-tailscan-convert-id="uwbvgywvv5rg7pb8507svd">Breakfast</span>
                    <span class="text-xs text-purple-600 dark:text-fuchsia-100"
                          data-tailscan-convert-id="46ou7jchd5nu3o7dmydyzf">Mel's Diner</span>
                </div>
                <div
                        class="row-start-[14] col-start-[7] row-span-3 bg-pink-400/20 dark:bg-indigo-600/50 border border-pink-700/10 dark:border-indigo-500 rounded-lg m-1 p-1 flex flex-col"
                        data-tailscan-convert-id="9tu1bjrexyg7shyxx0vnu9">
        <span class="text-xs text-pink-600 dark:text-indigo-100"
              data-tailscan-convert-id="8z0o84u63sfj77enl5vkg9">5 PM</span>
                    <span class="text-xs font-medium text-pink-600 dark:text-indigo-100"
                          data-tailscan-convert-id="hutssp72tan5puq9xx1abt">🎉 Party party 🎉</span>
                    <span class="text-xs text-pink-600 dark:text-indigo-100"
                          data-tailscan-convert-id="x2v1nyas7zfe67l3wmmso">We like to party!</span>
                </div>
            </div>
        </div>
    </div>
    <p class="py-20">Hello</p>
    <p class="py-20">Hello</p>
    <p class="py-20">Hello</p>
</div>



