import {Routes} from "@angular/router";
import {AuthComponent} from "./features/auth/pages/auth.component";
import {SignUpComponent} from "./features/auth/pages/sign-up/sign-up.component";
import {LoginComponent} from "./features/auth/pages/login/login.component";
import {ResetPasswordComponent} from "./features/auth/pages/reset-password/reset-password.component";
import {
  SetupCompanyProfileComponent
} from "./features/auth/pages/setup-company-profile/setup-company-profile.component";
import {DashboardComponent} from "./features/dashboard/pages/dashboard.component";
import {LayoutComponent} from "./features/layout/layout.component";
import {DriversListComponent} from "./features/drivers/pages/drivers-list/drivers-list.component";
import {ForgotPasswordComponent} from "./features/auth/pages/forgot-password/forgot-password.component";
import {SetupPasswordComponent} from "./features/auth/pages/setup-password/setup-password.component";
import {authGuard} from "./core/api/auth.guard";
import {PageNotFoundComponent} from "./features/layout/page-not-found/page-not-found.component";
import {
  DriverApplicationComponent
} from "./features/driver-application/pages/complete-driver-application/driver-application.component";
import {
  DriverApplicationListComponent
} from "./features/driver-application/pages/driver-application-list/driver-application-list.component";
import {TestComponent} from "./features/test/test.component";
import {
  SaveApplicantCompleteApplicationComponent
} from "./features/driver-application/pages/complete-driver-application/save-applicant-complete-application/save-applicant-complete-application.component";
import {
  DriverIncompleteApplicationComponent
} from "./features/driver-application/pages/complete-driver-application/driver-incomplete-application/driver-incomplete-application.component";
import {
  DriverHiringComponent
} from "./features/driver-application/pages/driver-application-list/driver-hiring/driver-hiring.component";
import {
  ProficiencyTestComponent
} from "./features/driver-application/pages/driver-application-list/proficiency-test/proficiency-test.component";

export const routes: Routes = [
  {path: "", redirectTo: "/login", pathMatch: "full"},
  {path: "test", component: TestComponent},
  {
    path: "",
    component: AuthComponent,
    children: [
      {path: "sign-up", component: SignUpComponent},
      {path: "login", component: LoginComponent},
      {path: "setup-password/:token", component: SetupPasswordComponent},
      {path: "forgot-password", component: ForgotPasswordComponent},
      {path: "reset-password/:token", component: ResetPasswordComponent},
    ],
  },
  {
    path: "setup-company-profile",
    component: SetupCompanyProfileComponent,
    canActivate: [authGuard],
  },
  {
    path: "driver-application/apply",
    component: DriverApplicationComponent,
    canActivate: [],
  },
  {
    path: "",
    canActivate: [authGuard],
    component: LayoutComponent,
    children: [
      {path: "dashboard", component: DashboardComponent},
      {path: "drivers", component: DriversListComponent},
      {path: "driver-applications", component: DriverApplicationListComponent},
      {path: "hire", component:DriverHiringComponent},
      {path:"proficiency-test", component: ProficiencyTestComponent},
    ],
  },
  {
    path: "complete-driver-application",
    component: SaveApplicantCompleteApplicationComponent,
  },
  {path: "**", component: PageNotFoundComponent},
];
