import { Component, Input, inject, input, signal } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AppSvgIconComponent } from "../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {
  SideMenuItem,
  SidebarMenuComponent,
} from "../sidebar/sidebar-menu/sidebar-menu.component";
import { RouterLink } from "@angular/router";
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { TokenStoreService } from "../../../../core/services/token/token-store.service";
import { LayoutService } from "../../layout.service";

@Component({
  selector: "app-top-bar",
  standalone: true,
  imports: [
    AppSvgIconComponent,
    CommonModule,
    SidebarMenuComponent,
    RouterLink,
    MatMenu,
    MatMenuItem,
    MatMenuTrigger,
  ],
  templateUrl: "./top-bar.component.html",
  styleUrl: "./top-bar.component.scss",
})
export class TopBarComponent {
  tokenService = inject(TokenStoreService);
  layoutService = inject(LayoutService);
  currentMenu = this.layoutService.currentMenu;

  onLogoutClick() {
    this.tokenService.logout();
  }
}
