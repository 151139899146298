import {Component, ElementRef, inject, OnInit, QueryList, signal, ViewChildren} from '@angular/core';
import {DropdownComponent} from "../../shared/inputs/dropdown/dropdown.component";
import {ControlContainer, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {
  OutlineTextInputComponent
} from "../../shared/inputs/outline-text-input/outline-text-input.component";
import {ButtonComponent} from "../../shared/components/button/button.component";
import {BaseComponent} from "../../shared/base/base-component";

@Component({
  selector: 'app-test',
  standalone: true,
  imports: [
    DropdownComponent,
    FormsModule,
    OutlineTextInputComponent,
    ReactiveFormsModule,
    ButtonComponent
  ],
  templateUrl: './test.component.html',
  styleUrl: './test.component.scss'
})
export class TestComponent extends BaseComponent implements OnInit {

  formBuilder = inject(FormBuilder);

  form!: FormGroup;

  countries = signal<Country[]>([]);
  selectedCountry: Country | null = null;

  ngOnInit(): void {
    this.initForm();
    this.countries.set([
      {name: "Afghanistan", code: "AF", continent: "Asia"},
      {name: "Albania", code: "AL", continent: "Europe"},
      {name: "Algeria", code: "DZ", continent: "Africa"},
      {name: "Andorra", code: "AD", continent: "Europe"},
      {name: "Angola", code: "AO", continent: "Africa"},
      {name: "Antigua and Barbuda", code: "AG", continent: "North America"},
      {name: "Argentina", code: "AR", continent: "South America"},
      {name: "Armenia", code: "AM", continent: "Asia"},
      {name: "Australia", code: "AU", continent: "Oceania"},
      {name: "Austria", code: "AT", continent: "Europe"},
      {name: "Azerbaijan", code: "AZ", continent: "Asia"},
      {name: "Bahamas", code: "BS", continent: "North America"},
      {name: "Bahrain", code: "BH", continent: "Asia"},
      {name: "Bangladesh", code: "BD", continent: "Asia"},
      {name: "Barbados", code: "BB", continent: "North America"},
      {name: "Belarus", code: "BY", continent: "Europe"},
      {name: "Belgium", code: "BE", continent: "Europe"},
      {name: "Belize", code: "BZ", continent: "North America"},
      {name: "Benin", code: "BJ", continent: "Africa"},
      {name: "Bhutan", code: "BT", continent: "Asia"},
      {name: "Bolivia", code: "BO", continent: "South America"},
      {name: "Bosnia and Herzegovina", code: "BA", continent: "Europe"},
      {name: "Botswana", code: "BW", continent: "Africa"},
      {name: "Brazil", code: "BR", continent: "South America"},
      {name: "Brunei", code: "BN", continent: "Asia"},
      {name: "Bulgaria", code: "BG", continent: "Europe"},
      {name: "Burkina Faso", code: "BF", continent: "Africa"},
      {name: "Burundi", code: "BI", continent: "Africa"},
      {name: "Cabo Verde", code: "CV", continent: "Africa"},
      {name: "Cambodia", code: "KH", continent: "Asia"},
      {name: "Cameroon", code: "CM", continent: "Africa"},
      {name: "Canada", code: "CA", continent: "North America"},
      {name: "Central African Republic", code: "CF", continent: "Africa"},
      {name: "Chad", code: "TD", continent: "Africa"},
      {name: "Chile", code: "CL", continent: "South America"},
      {name: "China", code: "CN", continent: "Asia"},
      {name: "Colombia", code: "CO", continent: "South America"},
      {name: "Comoros", code: "KM", continent: "Africa"},
      {name: "Congo, Democratic Republic of the", code: "CD", continent: "Africa"},
      {name: "Congo, Republic of the", code: "CG", continent: "Africa"},
      {name: "Costa Rica", code: "CR", continent: "North America"},
      {name: "Croatia", code: "HR", continent: "Europe"},
      {name: "Cuba", code: "CU", continent: "North America"},
      {name: "Cyprus", code: "CY", continent: "Europe"},
      {name: "Czech Republic", code: "CZ", continent: "Europe"},
      {name: "Denmark", code: "DK", continent: "Europe"},
      {name: "Djibouti", code: "DJ", continent: "Africa"},
      {name: "Dominica", code: "DM", continent: "North America"},
      {name: "Dominican Republic", code: "DO", continent: "North America"},
      {name: "East Timor (Timor-Leste)", code: "TL", continent: "Asia"},
      {name: "Ecuador", code: "EC", continent: "South America"},
      {name: "Egypt", code: "EG", continent: "Africa"},
      {name: "El Salvador", code: "SV", continent: "North America"},
      {name: "Equatorial Guinea", code: "GQ", continent: "Africa"},
      {name: "Eritrea", code: "ER", continent: "Africa"},
      {name: "Estonia", code: "EE", continent: "Europe"},
      {name: "Eswatini", code: "SZ", continent: "Africa"},
      {name: "Ethiopia", code: "ET", continent: "Africa"},
      {name: "Fiji", code: "FJ", continent: "Oceania"},
      {name: "Finland", code: "FI", continent: "Europe"},
      {name: "France", code: "FR", continent: "Europe"},
      {name: "Gabon", code: "GA", continent: "Africa"},
      {name: "Gambia", code: "GM", continent: "Africa"},
      {name: "Georgia", code: "GE", continent: "Asia"},
      {name: "Germany", code: "DE", continent: "Europe"},
      {name: "Ghana", code: "GH", continent: "Africa"},
      {name: "Greece", code: "GR", continent: "Europe"},
      {name: "Grenada", code: "GD", continent: "North America"},
      {name: "Guatemala", code: "GT", continent: "North America"},
      {name: "Guinea", code: "GN", continent: "Africa"},
      {name: "Guinea-Bissau", code: "GW", continent: "Africa"},
      {name: "Guyana", code: "GY", continent: "South America"},
      {name: "Haiti", code: "HT", continent: "North America"},
      {name: "Honduras", code: "HN", continent: "North America"},
      {name: "Hungary", code: "HU", continent: "Europe"},
      {name: "Iceland", code: "IS", continent: "Europe"},
      {name: "India", code: "IN", continent: "Asia"},
      {name: "Indonesia", code: "ID", continent: "Asia"},
      {name: "Iran", code: "IR", continent: "Asia"},
      {name: "Iraq", code: "IQ", continent: "Asia"},
      {name: "Ireland", code: "IE", continent: "Europe"},
      {name: "Israel", code: "IL", continent: "Asia"},
      {name: "Italy", code: "IT", continent: "Europe"},
      {name: "Ivory Coast", code: "CI", continent: "Africa"},
      {name: "Jamaica", code: "JM", continent: "North America"},
      {name: "Japan", code: "JP", continent: "Asia"},
      {name: "Jordan", code: "JO", continent: "Asia"},
      {name: "Kazakhstan", code: "KZ", continent: "Asia"},
      {name: "Kenya", code: "KE", continent: "Africa"},
      {name: "Kiribati", code: "KI", continent: "Oceania"},
      {name: "Korea, North", code: "KP", continent: "Asia"},
      {name: "Korea, South", code: "KR", continent: "Asia"},
      {name: "Kuwait", code: "KW", continent: "Asia"},
      {name: "Kyrgyzstan", code: "KG", continent: "Asia"},
      {name: "Laos", code: "LA", continent: "Asia"},
      {name: "Latvia", code: "LV", continent: "Europe"},
      {name: "Lebanon", code: "LB", continent: "Asia"},
      {name: "Lesotho", code: "LS", continent: "Africa"},
      {name: "Liberia", code: "LR", continent: "Africa"},
      {name: "Libya", code: "LY", continent: "Africa"},
    ]);
  }

  changeed($event: any) {

  }

  private initForm() {
    this.form = this.formBuilder.group({
      q: ['q'],
      w: ['w'],
      e: ['e'],
      r: ['r'],
      t: ['t'],
      y: ['y'],
      u: ['u'],
      i: ['i'],
      yeOrNo: [false],
      o: ['o', [Validators.required]],
      p: ['p', [Validators.required]],
      a: ['a', [Validators.required]],
      s: ['s', [Validators.required]],
      d: ['d', [Validators.required]],
      f: ['', [Validators.required]],
      g: ['', [Validators.required]],
      h: ['', [Validators.required]],
      j: ['', [Validators.required]],
      k: ['', [Validators.required]],
      l: ['', [Validators.required]],
      z: ['', [Validators.required]],
      x: ['', [Validators.required]],
    });
  }

  onValidate() {
    this.validateForm(this.form);
  }

}

interface Country {
  name: string;
  code: string;
  continent: string;
}
