import {Component, inject, OnInit, output} from "@angular/core";
import {FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators,} from "@angular/forms";
import {
  OutlineTextInputComponent
} from "../../../../../shared/inputs/outline-text-input/outline-text-input/outline-text-input.component";
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {RadioButtonComponent} from "../../../../../shared/inputs/radio-button/radio-button.component";
import {CommonModule} from "@angular/common";
import {
  MultiSelectionFieldComponent
} from "../../../../../shared/inputs/multi-selection-field/multi-selection-field.component";
import {DropdownComponent} from "../../../../../shared/inputs/dropdown/dropdown.component";
import {BaseComponent} from "../../../../../shared/base/base-component";
import {
  SingleItemPickerInputComponent
} from "../../../../../shared/inputs/single-item-picker-input/single-item-picker-input.component";
import {
  SingleSelectionFieldComponent
} from "../../../../../shared/inputs/single-selection-field/single-selection-field.component";
import {State} from "../../../../../shared/base/base-state";
import {CommonService} from "../../../../../core/services/common/common.service";
import {
  ClassOfEquipmentResponse
} from "../../../../../core/services/common/class-of-equipment/class-of-equipment-response";
import {
  ApplicantExperience,
  ApplicantExperienceResponse
} from "../../../../../core/services/driver-application/applicant-experience-info/applicant-experience-response";
import {DriverApplicationService} from "../../../../../core/services/driver-application/driver-application.service";
import {
  ApplicantExperienceRequest
} from "../../../../../core/services/driver-application/applicant-experience-info/applicant-experience-request";
import {TokenStoreService} from "../../../../../core/services/token/token-store.service";
import {
  SaveApplicantExperience,
  SaveApplicantExperienceRequest
} from "../../../../../core/services/driver-application/save-applicant-experience/save-applicant-experience-request";
import {DateUtils} from "../../../../../shared/utils/date-utils";

@Component({
  selector: "app-save-driving-experience-info",
  templateUrl: "./save-driving-experience-info.component.html",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    OutlineTextInputComponent,
    ButtonComponent,
    RadioButtonComponent,
    CommonModule,
    MultiSelectionFieldComponent,
    DropdownComponent,
    SingleItemPickerInputComponent,
    SingleSelectionFieldComponent,
  ],
  styleUrls: ["./save-driving-experience-info.component.scss"],
})
export class SaveDrivingExperienceInfoComponent extends BaseComponent implements OnInit {
  onNextClick = output<void>();
  onPreviousClick = output<void>();

  commonService = inject(CommonService);
  formBuilder = inject(FormBuilder);
  driverApplicationService = inject(DriverApplicationService);
  tokenService = inject(TokenStoreService);

  drivingExperienceForm!: FormGroup;
  drivingExperienceFormArray!: FormArray;

  classOfEquipmentState = new State<ClassOfEquipmentResponse>();
  drivingExperiencesState = new State<ApplicantExperienceResponse>();
  saveDrivingExperienceState = new State<any>();

  ngOnInit(): void {
    this.initForm();
    this.getClassOfEquipment();
    this.getDrivingExperienceInfo();
  }

  private initForm() {
    this.drivingExperienceFormArray = this.formBuilder.array([]);
    this.drivingExperienceForm = this.formBuilder.group({
      hasCommercialDrivingExperience: [false, Validators.required],
      drivingExperiences: this.drivingExperienceFormArray
    });
  }

  private getClassOfEquipment() {
    this.executeRequest({
      state: this.classOfEquipmentState,
      request: this.commonService.getClassOfEquipment()
    });
  }

  getDrivingExperienceInfo() {
    let request: ApplicantExperienceRequest = {
      driverApplicationId: this.tokenService.getDriverApplicationId()
    };
    this.executeRequest<ApplicantExperienceResponse>({
      state: this.drivingExperiencesState,
      request: this.driverApplicationService.getApplicantExperiences(request),
      onSuccess: response => {
        let hasCommercialDrivingExperience = response.hasCommercialDrivingExperience;
        this.drivingExperienceForm.controls['hasCommercialDrivingExperience'].setValue(hasCommercialDrivingExperience ?? false);
        this.populateForm(response.data ?? [], hasCommercialDrivingExperience);
      }
    });
  }

  private populateForm(applicantExperiences: ApplicantExperience[], hasCommercialDrivingExperience: boolean) {
    if (applicantExperiences.length <= 0) {
      let form = this.createDrivingExperienceForm(null);
      this.drivingExperienceFormArray.push(form);
    } else {
      applicantExperiences.forEach((experience, index) => {
        let form = this.createDrivingExperienceForm(experience);
        this.drivingExperienceFormArray.push(form);
      });
    }
  }

  createDrivingExperienceForm(experience: ApplicantExperience | null): FormGroup {
    let dateUtils = new DateUtils();
    return this.formBuilder.group({
      // id:[experience?.id],
      classOfEquipment: [experience?.classOfEquipment, Validators.required],
      dateFrom: [experience?.dateFrom, Validators.required],
      dateTo: [experience?.dateTo, Validators.required],
      approximateMiles: [experience?.miles, Validators.required],
    });
  }

  onAddExperienceClicked() {
    let newForm = this.createDrivingExperienceForm(null);
    this.drivingExperienceFormArray.push(newForm);
  }

  onDeleteClicked(i: number) {
    this.drivingExperienceFormArray.removeAt(i);
  }

  onSaveAndNextClicked() {
    this.validateForm(this.drivingExperienceForm);
    let hasCommercialDrivingExperience = this.drivingExperienceForm.value.hasCommercialDrivingExperience;
    if (this.drivingExperienceForm.invalid && hasCommercialDrivingExperience) {
      return;
    }

    let request: SaveApplicantExperienceRequest = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
      hasCommercialDrivingExperience: hasCommercialDrivingExperience,
      experiences: []
    };

    let experiences: SaveApplicantExperience[] = [];

    this.drivingExperienceFormArray.controls.forEach(control => {
      let experience = control.value;
      let dateUtils = new DateUtils();
      experiences.push({
        classOfEquipment: experience.classOfEquipment,
        dateFrom: dateUtils.convertToMMYYYYString(experience.dateFrom),
        dateTo: dateUtils.convertToMMYYYYString(experience.dateTo),
        miles: Number(experience.approximateMiles ?? 0)
      });
    });

    if(hasCommercialDrivingExperience) {
      request.experiences = experiences;
    }


    this.executeRequest({
      state: this.saveDrivingExperienceState,
      request: this.driverApplicationService.saveApplicantExperiences(request),
      showLoader: true,
      handleSuccess: true,
      handleError: true,
      onSuccess: response => {
        this.onNextClick.emit();
      }
    });
  }

  onPreviousClicked() {
    this.onPreviousClick.emit();
  }

}
