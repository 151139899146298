import {Component, inject, OnInit, output} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import {RadioButtonComponent} from "../../../../../shared/inputs/radio-button/radio-button.component";
import {
  OutlineTextInputComponent
} from "../../../../../shared/inputs/outline-text-input/outline-text-input/outline-text-input.component";
import {
  SingleSelectionFieldComponent
} from "../../../../../shared/inputs/single-selection-field/single-selection-field.component";
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {DriverApplicationService} from "../../../../../core/services/driver-application/driver-application.service";
import {TokenStoreService} from "../../../../../core/services/token/token-store.service";
import {
  SaveApplicantAccident,
  SaveApplicantAccidentsRequest
} from "../../../../../core/services/driver-application/save-applicant-accidents/save-applicant-accidents-request";
import {BaseComponent} from "../../../../../shared/base/base-component";
import {
  ApplicantAccident,
  ApplicantAccidentsInfoResponse
} from "../../../../../core/services/driver-application/applicant-accidents-info/applicant-accidents-info-response";
import {
  ApplicantAccidentInfoRequest
} from "../../../../../core/services/driver-application/applicant-accidents-info/applicant-accidents-info-request";
import {State} from "../../../../../shared/base/base-state";
import {DateUtils} from "../../../../../shared/utils/date-utils";

@Component({
  selector: "app-save-applicant-accident-info",
  standalone: true,
  imports: [
    FormsModule,
    RadioButtonComponent,
    ReactiveFormsModule,
    OutlineTextInputComponent,
    SingleSelectionFieldComponent,
    ButtonComponent,
  ],
  templateUrl: "./save-applicant-accident-info.component.html",
  styleUrl: "./save-applicant-accident-info.component.scss",
})
export class SaveApplicantAccidentInfoComponent extends BaseComponent implements OnInit {
  onNextClick = output<void>();
  onPreviousClick = output<void>();

  formBuilder = inject(FormBuilder);
  driverApplicationService = inject(DriverApplicationService);
  tokenService = inject(TokenStoreService);

  accidentsForm!: FormGroup;
  accidentsFormArray!: FormArray;

  applicantAccidentsState = new State<ApplicantAccidentsInfoResponse>();
  saveAccidentsState = new State<any>();

  ngOnInit() {
    this.initForm();
    this.getAccidents();
  }

  private initForm() {
    this.accidentsFormArray = this.formBuilder.array([]);
    this.accidentsForm = this.formBuilder.group({
      causedDrivingAccident: [false],
      accidents: this.accidentsFormArray,
    });
  }

  getAccidents() {
    let request: ApplicantAccidentInfoRequest = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
    };

    this.executeRequest<ApplicantAccidentsInfoResponse>({
      state: this.applicantAccidentsState,
      request: this.driverApplicationService.getApplicantAccidents(request),
      onSuccess: response => {
        let causedDrivingAccident = response.causedDrivingAccident;
        this.accidentsForm.controls['causedDrivingAccident'].setValue(causedDrivingAccident ?? false);
        this.populateForm(response.data ?? []);
      }
    });
  }

  private populateForm(accidents: ApplicantAccident[]) {
    if (accidents.length <= 0) {
      let form = this.createApplicantAccidentForm(null);
      this.accidentsFormArray.push(form);
    } else {
      accidents.forEach(accident => {
        let form = this.createApplicantAccidentForm(accident);
        this.accidentsFormArray.push(form);
      });
    }
  }

  createApplicantAccidentForm(accident: ApplicantAccident | null) {
    let dateUtils = new DateUtils();
    return this.formBuilder.group({
      id: [accident?.id],
      accidentDate: [dateUtils.convertToMMDDYYYY(accident?.accidentDate), Validators.required],
      natureOfAccident: [accident?.natureOfAccident, Validators.required],
      fatalities: [accident?.fatalities, Validators.required],
      injuries: [accident?.injuries, Validators.required],
      hasChemicalSpills: [accident?.hasChemicalSpills ?? false],
    });
  }

  onAddMoreAccidentsClicked() {
    let newForm = this.createApplicantAccidentForm(null);
    this.accidentsFormArray.push(newForm);
  }

  onDeleteClicked(index: number): void {
    if (this.accidentsFormArray.length > 1)
      this.accidentsFormArray.removeAt(index);
  }

  onSaveAndNextClicked() {
    this.validateForm(this.accidentsForm);
    let causedDrivingAccident = this.accidentsForm.value.causedDrivingAccident;
    if (this.accidentsForm.invalid && causedDrivingAccident) {
      return;
    }

    let request: SaveApplicantAccidentsRequest = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
      causedDrivingAccident: causedDrivingAccident,
      accidents: []
    };

    let accidents: SaveApplicantAccident[] = [];

    this.accidentsFormArray.controls.forEach(control => {
      let accident = control.value;
      let dateUtils = new DateUtils();
      accidents.push({
        accidentDate: dateUtils.convertToDateTimeString(accident.accidentDate)!,
        natureOfAccident: accident.natureOfAccident,
        fatalities: accident.fatalities,
        hasChemicalSpills: accident.hasChemicalSpills,
        injuries: accident.injuries,
      })
      ;
    });

    if (causedDrivingAccident) {
      request.accidents = accidents;
    }

    this.executeRequest({
      state: this.saveAccidentsState,
      request: this.driverApplicationService.saveApplicantAccidents(request),
      showLoader: true,
      handleSuccess: true,
      handleError: true,
      onSuccess: response => {
        this.onNextClick.emit();
      }
    });
  }

  onPreviousClicked() {
    this.onPreviousClick.emit();
  }
}
