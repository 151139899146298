import {Component, OnInit, inject, output} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ReactiveFormsModule} from "@angular/forms";
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {OutlineTextInputComponent} from "../../../../../shared/inputs/outline-text-input/outline-text-input.component";
import {BaseComponent} from "../../../../../shared/base/base-component";
import {TokenStoreService} from "../../../../../core/services/token/token-store.service";
import {State} from "../../../../../shared/base/base-state";
import {DriverApplicationService} from "../../../../../core/services/driver-application/driver-application.service";
import {
  ApplicantGeneralInfoResponse
} from "../../../../../core/services/driver-application/applicant-general-info/applicant-general-info-response";
import {DateUtils} from "../../../../../shared/utils/date-utils";
import {
  SaveApplicantGeneralInfoRequest
} from "../../../../../core/services/driver-application/save-applicant-general-info/save-applicant-general-info-request";
import {RadioButtonComponent} from "../../../../../shared/inputs/radio-button/radio-button.component";
import {
  SaveApplicantGeneralInfoResponse
} from "../../../../../core/services/driver-application/save-applicant-general-info/save-applicant-general-info-response";
import {
  InvalidControlScrollContainerDirective
} from "../../../../../shared/directives/invalid-control-scroll-container.directive";
import {FileUploaderComponent} from "../../../../../shared/inputs/file-uploader/file-uploader.component";

@Component({
  selector: "app-save-applicant-general-info",
  standalone: true,
  imports: [
    ButtonComponent,
    OutlineTextInputComponent,
    ReactiveFormsModule,
    RadioButtonComponent,
    InvalidControlScrollContainerDirective,
    FileUploaderComponent,
  ],
  templateUrl: "./save-applicant-general-info.component.html",
  styleUrl: "./save-applicant-general-info.component.scss",
})
export class SaveApplicantGeneralInfoComponent
  extends BaseComponent
  implements OnInit {
  onNextClick = output<void>();

  formBuilder = inject(FormBuilder);
  tokenService = inject(TokenStoreService);
  driverService = inject(DriverApplicationService);
  applicantGeneralInfoForm!: FormGroup;
  applicantGeneralInfoState = new State<ApplicantGeneralInfoResponse>();
  saveApplicantGeneralInfoState = new State<SaveApplicantGeneralInfoResponse>();

  ngOnInit(): void {
    this.initForm();
    this.getApplicantGeneralInfo();
  }

  private initForm() {
    this.applicantGeneralInfoForm = this.formBuilder.group({
      id: [""],
      driverApplicationId: [],
      firstName: ["", Validators.required],
      middleName: [""],
      lastName: ["", Validators.required],
      phoneNumber: ["", Validators.required],
      email: [""],
      dateOfBirth: ["", Validators.required],
      socialSecurityNumber: ["", Validators.required],
      dateOfApplication: [""],
      position: [""],
      dateAvailableForWork: [""],
      hasUsWorkLegality: [false, Validators.required],
    });
  }

  getApplicantGeneralInfo() {
    this.executeRequest<ApplicantGeneralInfoResponse>({
      state: this.applicantGeneralInfoState,
      request: this.driverService.getApplicationGeneralInfo(
        this.tokenService.getDriverApplicationId() ?? ""
      ),
      onSuccess: (response) => {
        let dateUtils = new DateUtils();
        let generalInfo = response.data;

        this.applicantGeneralInfoForm.controls["id"].setValue(generalInfo.id);
        this.applicantGeneralInfoForm.controls["driverApplicationId"].setValue(
          this.tokenService.getDriverApplicationId()
        );
        this.applicantGeneralInfoForm.controls["firstName"].setValue(
          generalInfo.firstName
        );
        this.applicantGeneralInfoForm.controls["middleName"].setValue(
          generalInfo.middleName
        );
        this.applicantGeneralInfoForm.controls["lastName"].setValue(
          generalInfo.lastName
        );
        this.applicantGeneralInfoForm.controls["phoneNumber"].setValue(
          generalInfo.phoneNumber
        );
        this.applicantGeneralInfoForm.controls["email"].setValue(
          generalInfo.email
        );
        this.applicantGeneralInfoForm.controls["dateOfBirth"].setValue(
          dateUtils.convertToMMDDYYYY(generalInfo.dateOfBirth)
        );
        this.applicantGeneralInfoForm.controls["socialSecurityNumber"].setValue(
          generalInfo.socialSecurityNumber
        );
        this.applicantGeneralInfoForm.controls["dateOfApplication"].setValue(
          dateUtils.convertToMMDDYYYY(generalInfo.dateOfApplication)
        );
        this.applicantGeneralInfoForm.controls["position"].setValue(
          generalInfo.position
        );
        this.applicantGeneralInfoForm.controls["dateAvailableForWork"].setValue(
          dateUtils.convertToMMDDYYYY(generalInfo.dateAvailableForWork)
        );
        this.applicantGeneralInfoForm.controls["hasUsWorkLegality"].setValue(
          generalInfo.hasUsWorkLegality
        );

        this.applicantGeneralInfoForm.controls["dateOfApplication"].disable();
        this.applicantGeneralInfoForm.controls["email"].disable();
      },
    });
  }

  onSaveAndNextClicked() {
    this.validateForm(this.applicantGeneralInfoForm);
    if (this.applicantGeneralInfoForm.invalid) {
      return;
    }

    let dateUtils = new DateUtils();
    let request: SaveApplicantGeneralInfoRequest = {
      id: this.applicantGeneralInfoForm.controls["id"].value,
      driverApplicationId:
      this.applicantGeneralInfoForm.controls["driverApplicationId"].value,
      firstName: this.applicantGeneralInfoForm.controls["firstName"].value,
      middleName: this.applicantGeneralInfoForm.controls["middleName"].value,
      lastName: this.applicantGeneralInfoForm.controls["lastName"].value,
      phoneNumber: this.applicantGeneralInfoForm.controls["phoneNumber"].value,
      dateOfBirth: dateUtils.convertToDateTimeString(
        this.applicantGeneralInfoForm.controls["dateOfBirth"].value
      )!,
      socialSecurityNumber:
      this.applicantGeneralInfoForm.controls["socialSecurityNumber"].value,
      position: this.applicantGeneralInfoForm.controls["position"].value,
      dateAvailableForWork: dateUtils.convertToDateTimeString(
        this.applicantGeneralInfoForm.controls["dateAvailableForWork"].value
      )!,
      hasUsWorkLegality:
      this.applicantGeneralInfoForm.controls["hasUsWorkLegality"].value,
    };

    this.executeRequest({
      state: this.saveApplicantGeneralInfoState,
      request: this.driverService.saveApplicationGeneralInfo(request),
      handleSuccess: true,
      onSuccess: (response) => {
        this.onNextClick.emit();
      },
    });
  }
}
