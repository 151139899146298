import { Component, inject, OnInit } from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { ButtonComponent } from "../../../../shared/components/button/button.component";
import { AppSvgIconComponent } from "../../../../shared/components/app-svg-icon/app-svg-icon.component";
import { OutlineTextInputComponent } from "../../../../shared/inputs/outline-text-input/outline-text-input.component";
import { BaseDialogComponent } from "../../../../shared/components/_base/base-dialog/base-dialog.component";
import { BaseComponent } from "../../../../shared/base/base-component";
import { State } from "../../../../shared/base/base-state";

import { DriverApplicationSentConfirmationComponent } from "../driver-application-sent-confirmation/driver-application-sent-confirmation.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Constants } from "../../../../core/constants/constants";
import { DriverApplicationService } from "../../../../core/services/driver-application/driver-application.service";
import { DriverService } from "../../../../core/services/driver/driver.service";
import { SendDriverApplicationRequest } from "../../../../core/services/driver/send-driver-application/send-driver-application.request";
import { SendDriverApplicationResponse } from "../../../../core/services/driver/send-driver-application/send-driver-application-response";

@Component({
  selector: "app-send-driver-application",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    ButtonComponent,
    AppSvgIconComponent,
    OutlineTextInputComponent,
    BaseDialogComponent,
  ],
  templateUrl: "./send-driver-application.component.html",
  styleUrl: "./send-driver-application.component.scss",
})
export class SendDriverApplicationComponent
  extends BaseComponent
  implements OnInit
{
  formBuilder = inject(FormBuilder);
  dialog = inject(MatDialog);
  dialogRef = inject(MatDialogRef);
  driverService = inject(DriverService);

  sendDriverApplicationForm!: FormGroup;
  sendDriverApplicationState = new State<SendDriverApplicationRequest>();

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.sendDriverApplicationForm = this.formBuilder.group({
      email: ["", Validators.required],
    });
  }

  onSendApplicationClicked() {
    let request = this.sendDriverApplicationForm.value;
    this.executeRequest({
      state: this.sendDriverApplicationState,
      request: this.driverService.sendDriverApplication(request),
      onSuccess: (response) => {
        this.dialogRef.close();
        this.openApplicationConfirmationDialog(response);
      },
    });
  }

  openApplicationConfirmationDialog(response: SendDriverApplicationResponse) {
    this.dialog.open(DriverApplicationSentConfirmationComponent, {
      data: { response },
      ...Constants.defaultDialogConfig,
    });
  }

  close() {
    this.dialog.closeAll();
  }
}
