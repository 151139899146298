<div class="px-4 space-y-4">
  <img src="../../../../../assets/images/branding/YSC-logo.png" />
  <p class="text-h6 py-8">Set password for your account</p>
  <form [formGroup]="setupPasswordForm">
    <div class="py-4">
      <app-outline-text-input [type]="'password'" formControlName="password" [label]="'Password'" placeholder="*********" class="w-full" [fullWidth]="true"></app-outline-text-input>
      <app-outline-text-input [type]="'password'" formControlName="confirmPassword" [label]="'Confirm Password'" placeholder="*********" class="w-full" [fullWidth]="true"></app-outline-text-input>
    </div>
    <app-button class="py-2" [fullWidth]="true" type="submit" [loading]="setupPasswordState.loading()" (click)="onSetPasswordClicked()">Set Password</app-button>
  </form>
</div>
