<form [formGroup]="changeDriverApplicationStatusForm">
  <app-base-dialog [title]="'Reason for changing the status of the application'">
    <div body>
      <div class="flex flex-col gap-4 my-6">
        <span class="text-body2">Please enter a valid reason for changing the status of the application.</span>
        <app-outline-text-input formControlName="statusChange" [label]="'Reason'" placeholder="Ex: Strong experience and excellent safety record." class="w-full" [fullWidth]="true"></app-outline-text-input>
      </div>
    </div>
    <div actions>
      <div class="flex justify-end gap-2">
        <app-button [loading]="changeDriverApplicationState.loading()" [type]="'submit'">Submit</app-button>
      </div>
    </div>
  </app-base-dialog>
</form>


