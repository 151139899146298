<div class="app-container">
  <div class="flex flex-col gap-4 bg-white p-4 rounded-md">
    <div class="mx-auto py-4">
      <p class="p-2 text-body1 text-center">HIRE APPLICANT</p>
      <p class="p-2 text-body1 text-center">Please upload the required documents to complete the hiring process</p>
    </div>
    <form [formGroup]="driverHiringForm">
      <div class="flex flex-col p-6 gap-6">
        <app-file-uploader formControlName="preEmploymentClearingHouse" label="Attach Pre-employment Clearinghouse query" [isRequired]="true"></app-file-uploader>
        <div class="flex flex-col gap-4">
          <p class="text-body2 text-neutral-800">Please complete full clearing house using the provided link below</p>
          <p class="text-body2 text-info-500 cursor-pointer underline">https://clearinghouse.fmcsa.dot.gov</p>
        </div>

        <div class="grid lg:grid-cols-2 gap-4">
          <app-outline-text-input formControlName="fullClearingHouseQueryCompletedDate" [label]="'Full clearinghouse query completed date'" mask="00/00/0000" class="w-full" [fullWidth]="true"></app-outline-text-input>

          <app-dropdown
            formControlName="fullClearingHouseQueryUploadFrequency"
            [title]="'Clearinghouse query upload frequency'"
            [display]="'displayName'"
            placeholder="90 Days"
            [value]="'name'"
            [noDataMessage]="'No Options Available'"
            [fullWidth]="true"
          >
          </app-dropdown>
        </div>
        <app-file-uploader formControlName="drugAlcoholPolicy" label="Drug & Alcohol policy" [isRequired]="true"></app-file-uploader>
        <app-file-uploader formControlName="drugTestResultAttachment" label="Attach drug test result" [isRequired]="true"></app-file-uploader>
        <div class="grid lg:grid-cols-2 gap-4">
          <app-outline-text-input formControlName="mroVerificationDate" [label]="'MRO Verification Date'" placeholder="Ex: 05/22/2028" class="w-full" [fullWidth]="true"></app-outline-text-input>
        </div>
        <app-file-uploader formControlName="drugTestCCF" label="Attach drug test CCF (Custody Control Form)" [isRequired]="true"></app-file-uploader>
        <div class="flex flex-col py-4 gap-3">
          <span class="text-primary-600">Please provide the drug test result</span>
          <div class="flex gap-3">
            <app-radio-button formControlName="drugTestResult" groupName="drugTestResult" title="Negative" [value]="true"> </app-radio-button>
            <app-radio-button formControlName="drugTestResult" groupName="drugTestResult" title="Positive" [value]="false"> </app-radio-button>
          </div>
        </div>
        <div class="flex flex-col py-3 gap-3">
          <span class="text-primary-600">Do you want to start the SAP programme for this applicant?</span>
          <div class="flex gap-3">
            <app-radio-button formControlName="startSapProgram" groupName="startSapProgram" title="Yes" [value]="true"> </app-radio-button>
            <app-radio-button formControlName="startSapProgram" groupName="startSapProgram" title="No" [value]="false"> </app-radio-button>
          </div>
          <div class="flex flex-col py-3 gap-3">
            <p class="text-body1 text-primary-600">Please upload the "Return to Duty" if the have already in SAP Program</p>
            <app-file-uploader formControlName="returnToDutyDocument" label="Return to Duty document "></app-file-uploader>
            <app-file-uploader formControlName="attachDrugTestCCF" label="Attach drug test CCF (Custody Control Form) "></app-file-uploader>
            <app-file-uploader formControlName="clearingHouseQueryPolicy" label="Clearinghouse Limited Query Policy"></app-file-uploader>
          </div>
          <div class="flex flex-col py-3 gap-3">
            <app-file-uploader formControlName="dmvPrintout" label="DMV Printout / Motor Vehicle Record (MVR) " [isRequired]="true"></app-file-uploader>
            <app-file-uploader formControlName="medicalExaminerCertificate" label="Medical examiner certificate" [isRequired]="true"></app-file-uploader>
            <p class="text-body1 text-neutral-800">Please verify the medical examiner's listed on the National Registry</p>
            <p class="text-body2 text-info-500">https://nationalregistry.fmcsa.dot.gov/search-medical-examiners</p>
            <app-file-uploader formControlName="screenShotOfNationalRegistryReference" label="Screenshot of National Registry Reference"></app-file-uploader>
          </div>
          <div class="grid lg:grid-cols-2 py-3 gap-4">
            <app-outline-text-input
              formControlName="medicalExaminerCertificateExpirationDate"
              [label]="'Medical examiner certificate expiration date'"
              mask="00/00/0000"
              class="w-full"
              [fullWidth]="true"
            ></app-outline-text-input>
          </div>
          <app-file-uploader formControlName="screenShotOfNationalRegistryReference" label="Screenshot of National Registry Reference"></app-file-uploader>
          @if(driverHiringForm.get('startSapProgram')?.value && driverHiringForm.get('drugTestResult')?.value){
          <div class="flex flex-col py-6 gap-6">
              <app-file-uploader formControlName="clearingHouseLimitedQueryPolicy" label="Clearinghouse Limited Query Policy" [isRequired]="true"></app-file-uploader>
              <app-file-uploader formControlName="motorVehicleRecord" label="Motor Vehicle Record (MVR)" [isRequired]="true"></app-file-uploader>
              <app-file-uploader formControlName="medicalExaminerCertificate" label="Medical examiner certificate" [isRequired]="true"></app-file-uploader>
              <div class="grid lg:grid-cols-2 gap-4">
                  <app-outline-text-input
                      formControlName="medicalExaminerCertificateExpirationDate"
                      [label]="'Medical examiner certificate expiration date'"
                      mask="00/00/0000"
                      class="w-full"
                      [fullWidth]="true"
                  ></app-outline-text-input>
              </div>
              <app-file-uploader formControlName="proficiencyTestResult" label="Proficiency Test Result (Road test)" [isRequired]="true"></app-file-uploader>
              <p class="mx-auto items-center text-body2">OR</p>
              <div class="border border-success-500 runded-lg p-2 cursor-pointer w-full flex justify-center items-center">
                  <app-svg-icon src="assets/icons/menu-right.svg" class="text-success-500" [size]="32"></app-svg-icon>
                  <a class="text-success-500 text-body1" (click)="onStartProficiencyTest()">Start Proficiency Test (Road Test)</a>
              </div>
              <p class="text-body2 text-primary-600">California company related documents</p>
              <app-file-uploader formControlName="employerPullNotice" label="Employer Pull Notice (EPN)"></app-file-uploader>
          </div>
          } @if(companyInfoState.response()?.data?.businessTypeId === 'BUS') {
          <div class="flex flex-col py-4 gap-6">
              <p class="text-body2 text-primary-500">Required for SPAB (or) School Bus Company</p>
              <div class="flex flex-col py-3 gap-3">
                  <span class="text-primary-600">Does the driver has a SPAB Certificate?</span>
                  <div class="flex gap-3">
                      <app-radio-button formControlName="hasSPABCertificate" groupName="hasSPABCertificate" title="Yes" [value]="true"> </app-radio-button>
                      <app-radio-button formControlName="hasSPABCertificate" groupName="hasSPABCertificate" title="No" [value]="false"> </app-radio-button>
                  </div>
              </div>
              <app-file-uploader formControlName="spabCertificate" label="SPAB Certificate" [isRequired]="true"></app-file-uploader>
              <app-file-uploader formControlName="t1Certificate" label="T-01 Certification (Optional)"></app-file-uploader>
              <app-file-uploader formControlName="t2Certificate" label="T-02 Certification (Optional)"></app-file-uploader>
          </div>
          }
          <p class="text-body1 text-primary-500">HAZMAT related documents</p>
          <div class="flex flex-col py-3 gap-3">
            <span class="text-primary-600">Would this driver transports HAZMAT material?</span>
            <div class="flex gap-3">
              <app-radio-button formControlName="hazMat" groupName="hazMat" title="Yes" [value]="true"> </app-radio-button>
              <app-radio-button formControlName="hazMat" groupName="hazMat" title="No" [value]="false"> </app-radio-button>
            </div>
          </div>
          <app-file-uploader formControlName="hazMatCertificate" label="HAZMAT Certificate" [isRequired]="true"></app-file-uploader>
          <app-checkbox formControlName="confirmationOfRequirements" title="I confirm that all details provided by the job applicant have been verified, and the candidate meets the necessary requirements."> </app-checkbox>
        </div>

        <div class="grid grid-cols-2 gap-3">
          <app-button [fullWidth]="true" [textButtonColor]="'text-error-500'" [outlineColor]="'border-error-500'" [appearance]="'outline'">Reject Job Application</app-button>
          <app-button [fullWidth]="true">Hire Applicant</app-button>
        </div>
      </div>
    </form>
  </div>
</div>
