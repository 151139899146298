import {Component, ElementRef, inject, signal} from "@angular/core";
import {AppSvgIconComponent} from "../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {RouterLink} from "@angular/router";
import {CommonModule} from "@angular/common";
import {
  SidebarMenuComponent,
  SideMenuItem,
} from "./sidebar-menu/sidebar-menu.component";
import {LayoutService} from "../../layout.service";

@Component({
  selector: "app-sidebar",
  standalone: true,
  imports: [
    AppSvgIconComponent,
    RouterLink,
    CommonModule,
    SidebarMenuComponent,
  ],
  templateUrl: "./sidebar.component.html",
  styleUrl: "./sidebar.component.scss",
})
export class SidebarComponent {
  layoutService = inject(LayoutService);

  menus = signal<SideMenuItem[]>([
    {
      iconPath: "assets/icons/dashboard.svg",
      link: "/dashboard",
      label: "Dashboard",
    },
    {
      iconPath: "assets/icons/format-list-checkbox.svg",
      link: "/driver-applications",
      label: "Applications",
    },
    {
      iconPath: "assets/icons/drivers.svg",
      link: "/drivers",
      label: "Drivers",
    },
    {
      iconPath: "assets/icons/equipment.svg",
      link: "/equipment",
      label: "Equipment",
    },
  ]);

  settingsMenu = signal<SideMenuItem>({
    iconPath: "assets/icons/settings.svg",
    link: "/settings",
    label: "Settings",
  });

  toggleSidebar() {
    this.layoutService.toggleSidebar();
  }

  onMenuClick(menu: { label: string; iconPath: string }) {
    this.layoutService.setCurrentMenu(menu);
  }
}
