@if(showTitle()) {
  <div class="header flex justify-start items-center px-5 py-6 border-b border-neutral-200">
    <p class="text-body1">{{ title() }}</p>
    <p class="absolute right-3 rounded-2xl p-3 border border-neutral-300 cursor-pointer hover:bg-neutral-50"
       (click)="onCloseClicked()">

      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" fill="currentColor" class="size-3 text-neutral-700">
        <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="currentColor"/>
      </svg>

    </p>
  </div>
}

<mat-dialog-content class="mat-dialog-content body overflow-y-auto py-6 px-8 h-50 my-12">
  <ng-content select="[body]"></ng-content>
</mat-dialog-content>

@if(showAction()) {
  <div class="border-t">
    <mat-dialog-actions>
      <div class="w-full py-2">
        <ng-content select="[actions]"></ng-content>
      </div>
    </mat-dialog-actions>
  </div>
}
