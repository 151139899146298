import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ToasterComponent } from "./shared/components/toast/components/toaster/toaster.component";
import { LoaderComponent } from "./shared/components/loader/loader.component";
import { ResponsiveHelperComponent } from "./shared/components/responsive-helper/responsive-helper.component";
import { MatFormField, MatHint, MatLabel } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from "@angular/material/datepicker";
import {ClipboardModule} from "@angular/cdk/clipboard";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ToasterComponent, LoaderComponent, ResponsiveHelperComponent, MatFormField, MatInput, MatDatepickerInput, MatDatepickerToggle, MatDatepicker, MatLabel, MatHint],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {

}
