import {
  Component,
  ElementRef,
  inject,
  OnInit,
  Renderer2,
} from "@angular/core";
import { MatTab, MatTabLabel } from "@angular/material/tabs";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { CommonModule } from "@angular/common";
import { ButtonComponent } from "../../../../shared/components/button/button.component";
import { AppSvgIconComponent } from "../../../../shared/components/app-svg-icon/app-svg-icon.component";
import { OutlineTextInputComponent } from "../../../../shared/inputs/outline-text-input/outline-text-input/outline-text-input.component";
import { BaseComponent } from "../../../../shared/base/base-component";
import {
  DriverApplicationResponse,
  DriverApplication,
} from "../../../../core/services/driver-application/application/driver-application-response";
import { State } from "../../../../shared/base/base-state";
import { ShimmerComponent } from "../../../../shared/components/shimmer/shimmer.component";
import { NoDataComponent } from "../../../../shared/components/no-data/no-data.component";
import { NoDataTableComponent } from "../../../../shared/components/no-data-table/no-data-table.component";
import { BaseTableComponent } from "../../../../shared/components/_base/base-table/base-table.component";
import { ContextMenuIconComponent } from "../../../../shared/components/context-menu-icon/context-menu-icon.component";
import { DriverApplicationService } from "../../../../core/services/driver-application/driver-application.service";
import { TextButtonSmall } from "../../../../shared/components/text-button-small/text-button-small.component";
import { ResizableColumnDirective } from "../../../../shared/components/_base/base-table/resizable-column.directive";
import { TableResizableColumnsDirective } from "../../../../shared/components/_base/base-table/table-resizable-columns.directive";
import {
  SortableTableDirective,
  TableSortEvent,
} from "../../../../shared/components/_base/base-table/sortable-table.directive";
import { SendDriverApplicationComponent } from "../../../drivers/overlays/send-driver-application/send-driver-application.component";
import { Constants } from "../../../../core/constants/constants";
import {
  DriverApplicationStatusUpdateComponent
} from "./driver-application-status-update/driver-application-status-update.component";

@Component({
  selector: "app-driver-application-list",
  standalone: true,
  imports: [
    ButtonComponent,
    AppSvgIconComponent,
    OutlineTextInputComponent,
    ButtonComponent,
    OutlineTextInputComponent,
    CommonModule,
    MatTab,
    MatTabLabel,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatDialogModule,
    ShimmerComponent,
    NoDataComponent,
    NoDataTableComponent,
    BaseTableComponent,
    ContextMenuIconComponent,
    TextButtonSmall,
    ResizableColumnDirective,
    TableResizableColumnsDirective,
    SortableTableDirective,
  ],
  templateUrl: "./driver-application-list.component.html",
  styleUrl: "./driver-application-list.component.scss",
})
export class DriverApplicationListComponent
  extends BaseComponent
  implements OnInit
{
  dialog = inject(MatDialog);
  driverService = inject(DriverApplicationService);
  applicationState = new State<DriverApplicationResponse>();

  ngOnInit(): void {
    this.getDriverApplications();
  }

  getApplicationStatus(applicationStatus: string) {
    switch (applicationStatus) {
      case "Invited": {
        return ["Hire", "Reject"];

      }
      case "Application Started": {
        return ["Reject"];
      }
      case "Needs Approval": {
        return [
          "Under Verification",
          "Re-Submission Requested",
          "Hired",
          "Rejected",
        ];
      }
      case "Under Verification": {
        return ["Re-Submission Required", "Hired", "Rejected"];
      }
      case "Re-Submission Requested": {
        return [
          "Re-Verification Pending",
          "Under Verification",
          "Hired",
          "Rejected",
        ];
      }
      case "Re-Verification Pending": {
        return [
          "Re-Submission Required",
          "Under Verification",
          "Hired",
          "Rejected",
        ];
      }
      default: {
        return ["Invited"];
      }
    }
  }

  onStatusChange(item: DriverApplication, newStatus: string) {
    console.log(`Updating status for ${item.email} to ${newStatus}`);
    item.applicationStatus = newStatus;
    if(newStatus === 'Hire')
    {
      this.router.navigate(['/hire']);
    }
    else
    {
    this.openDialog(newStatus);
    }
  }

  openDialog(status:string)
  {
   const dialogRef= this.dialog.open(DriverApplicationStatusUpdateComponent,{
      width: "1000px",
      height:"380px",
      data:{status}
    });
    dialogRef.afterClosed().subscribe(result =>{
      console.log('Dialog closed with reason:', result);
    });
  }

  onSendDriverApplicationClicked() {
    this.dialog.open(SendDriverApplicationComponent, {
      ...Constants.defaultDialogConfig,
    });
  }

  getDriverApplications() {
    this.executeRequest<DriverApplicationResponse>({
      state: this.applicationState,
      request: this.driverService.getDriverApplication(),
    });
  }

  onEmailClick(application: DriverApplication) {
    this.router
      .navigate([`/driver-application/apply`], {
        queryParams: { applicationId: application.id },
      })
      .then();
  }

  sortData($event: TableSortEvent) {
    console.log($event);
  }
}
