import { Injectable } from '@angular/core';
import { BaseApiService } from "../../../shared/base/base-api.service";
import { URLs } from "../../api/urls";
import { LoginRequest } from "./login/login-request";
import { LoginResponse } from "./login/login-response";
import { SetupPasswordRequest } from './setup-password/setup-password-request';
import { ResetPasswordRequest } from './reset-password/reset-password-request';
import { SetCompanyProfileRequest } from './set-company-profile/set-company-profile-request';
import {
  ForgotPasswordComponent
} from "../../../features/auth/pages/forgot-password/forgot-password.component";

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseApiService {

  signup(request: any) {
    return this.post(URLs.signUp, request);
  }

  login(request: LoginRequest) {
    return this.post<LoginResponse>(URLs.login, request);
  }

  setupPassword(request: SetupPasswordRequest) {
    return this.put(URLs.setupPassword, request);
  }

  resetPassword(request: ResetPasswordRequest) {
    return this.put(URLs.resetPassword, request);
  }

  forgotPassword(request: ForgotPasswordComponent) {
    return this.put(URLs.forgotPassword, request);
  }

  setupCompanyProfile(request: SetCompanyProfileRequest) {
    return this.put(URLs.setupCompanyProfile, request);
  }

}
