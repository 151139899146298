<div class="container-2xl mx-auto px-4 py-4 flex flex-col rounded-lg">
  <div class="flex flex-col items-center gap-4">
    <div class="flex items-center gap-5">
      <img src="../../../../../../assets/images/branding/logo.png" />
      <div class="flex flex-col text-subtitle2 text-primary-500">
        <div>Your Safety</div>
        <div>Compliance</div>
      </div>
    </div>
    <img class="h-20 w-20 mt-4" src="../../../../../../assets/images/branding/mail-success.png" />
    <p class="text-body2">Thank you for signing up with us!</p>
    <div class="flex flex-col">
      <p class="text-caption text-success-500 text-center text-nowrap overflow-hidden max-w-full">We'll review your application and our business representative will contact you shortly</p>
    </div>
    <app-button (click)="closeDialogue()">Close</app-button>
  </div>
</div>
