import {Component, inject, OnInit} from "@angular/core";
import {AppSvgIconComponent} from "../../../shared/components/app-svg-icon/app-svg-icon.component";
import {BaseTableComponent, PagingEvent} from "../../../shared/components/_base/base-table/base-table.component";
import {ButtonComponent} from "../../../shared/components/button/button.component";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";

import {SortableTableDirective} from "../../../shared/components/_base/base-table/sortable-table.directive";
import {
  TableResizableColumnsDirective
} from "../../../shared/components/_base/base-table/table-resizable-columns.directive";
import {ContextMenuIconComponent} from "../../../shared/components/context-menu-icon/context-menu-icon.component";
import {TextButtonSmall} from "../../../shared/components/text-button-small/text-button-small.component";
import {Constants} from "../../../core/constants/constants";
import {MatDialog} from "@angular/material/dialog";
import {BaseComponent} from "../../../shared/base/base-component";
import {CreateUserComponent} from "./create-user/create-user.component";

import {GetUserListItem, type GetUserListParams, GetUserListResponse} from "../../../core/api/model";
import {State} from "../../../shared/base/base-state";

import {CommonModule} from "@angular/common";
import {UpdateUserComponent} from "./update-user/update-user.component";
import {ApiService} from "../../../core/api/api.service";
import {OutlineTextInputComponent} from "../../../shared/inputs/outline-text-input/outline-text-input.component";
import {ContextMenuComponent} from "../../../shared/components/context-menu/context-menu.component";
import {ContextMenuItemComponent} from "../../../shared/components/context-menu-item/context-menu-item.component";

@Component({
  selector: "app-users",
  standalone: true,
  imports: [
    AppSvgIconComponent,
    BaseTableComponent,
    ButtonComponent,
    MatMenu,
    MatMenuItem,
    OutlineTextInputComponent,
    SortableTableDirective,
    TableResizableColumnsDirective,
    MatMenuTrigger,
    ContextMenuIconComponent,
    TextButtonSmall,
    CommonModule,
    ContextMenuComponent,
    ContextMenuItemComponent,
  ],
  templateUrl: "./users.component.html",
  styleUrl: "./users.component.scss",
})
export class UsersComponent extends BaseComponent implements OnInit {
  apiService = inject(ApiService);
  dialog = inject(MatDialog);

  userListState = new State<GetUserListResponse>();

  searchText: string = '';

  ngOnInit() {

  }

  getUserList($event: PagingEvent) {
    let request: GetUserListParams = {
      pageNumber: $event.pageNumber,
      pageSize: $event.pageSize,
      search: this.searchText
    };

    this.executeRequest<GetUserListResponse>({
      request: this.apiService.getUserList(request),
      state: this.userListState,
    });
  }

  onAddUserClicked() {
    this.dialog.open(CreateUserComponent, {
      ...Constants.defaultDialogConfig,
    }).afterClosed().subscribe((isSuccess) => {
      if (isSuccess) {
        this.getUserList(Constants.defaultPaginationConfig);
      }
    });
  }

  onEditClicked(user: GetUserListItem) {
    this.dialog.open(UpdateUserComponent, {
      ...Constants.defaultDialogConfig, data: user,
    }).afterClosed().subscribe((isSuccess) => {
      if (isSuccess) {
        this.getUserList(Constants.defaultPaginationConfig);
      }
    });
  }

  onSearchTextChanged($event: string) {
    this.searchText = $event;
    this.getUserList(Constants.defaultPaginationConfig);
  }
}
