import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
  input,
  output,
  viewChild,
} from "@angular/core";
import SignaturePad from "signature_pad";

@Component({
  selector: "app-signature-pad",
  templateUrl: "./signature-pad.component.html",
  standalone: true,
  styleUrls: ["./signature-pad.component.scss"],
})
export class SignaturePadComponent implements AfterViewInit {
  @ViewChild("canvas") canvasEl!: ElementRef;

  width = input<string>("500");
  height = input<string>("200");
  backgroundColor = input<string>("rgba(255,255,255,0");
  penColor = input<string>("black");

  signatureChanged = output<string>();
  signatureCleared = output<void>();

  signaturePad?: SignaturePad;

  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement, {
      backgroundColor: this.backgroundColor(),
      penColor: this.penColor(),
    });
  }

  startDrawing(event: MouseEvent | TouchEvent) {
    this.signaturePad!.on();
  }

  moved(event: MouseEvent | TouchEvent) {
    if (event instanceof MouseEvent && event.buttons === 1) {
      this.signaturePad!.on();
    } else if (event instanceof TouchEvent) {
      this.signaturePad!.on();
    }
  }

  endDrawing() {
    this.signaturePad!.off();
    if (!this.signaturePad!.isEmpty()) {
      const base64Signature = this.signaturePad!.toDataURL();
      this.signatureChanged.emit(base64Signature);
      console.log(base64Signature);
    }
  }

  clearPad() {
    this.signaturePad!.clear();
    this.signatureCleared.emit();
  }
}
