import {Component, inject, input} from '@angular/core';
import {AppSvgIconComponent} from "../../app-svg-icon/app-svg-icon.component";
import {MatDialogActions, MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {ButtonComponent} from "../../button/button.component";

@Component({
  selector: 'app-base-dialog',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    MatDialogContent,
    ButtonComponent,
    MatDialogActions
  ],
  templateUrl: './base-dialog.component.html',
  styleUrl: './base-dialog.component.scss'
})
export class BaseDialogComponent {
  title = input.required<String>();
  showTitle = input(true);
  showAction = input(true);
  dialogRef = inject(MatDialogRef<any>);

  onCloseClicked() {
    this.dialogRef.close();
  }
}
