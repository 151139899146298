import { Component, OnInit, inject } from "@angular/core";
import {
  FormGroup,
  Validators,
  FormBuilder,
  ReactiveFormsModule,
} from "@angular/forms";
import { RouterLink } from "@angular/router";
import { BaseComponent } from "../../../../shared/base/base-component";
import { State } from "../../../../shared/base/base-state";
import { ButtonComponent } from "../../../../shared/components/button/button.component";
import { AppSvgIconComponent } from "../../../../shared/components/app-svg-icon/app-svg-icon.component";
import { OutlineTextInputComponent } from "../../../../shared/inputs/outline-text-input/outline-text-input/outline-text-input.component";
import { BaseInputComponent } from "../../../../shared/inputs/_base/base-input/base-input.component";
import { AuthService } from "../../../../core/services/auth/auth.service";
import { ForgotPasswordResponse } from "../../../../core/services/auth/forgot-password/forgot-password-response";

@Component({
  selector: "app-forgot-password",
  standalone: true,
  imports: [
    RouterLink,
    ReactiveFormsModule,
    ButtonComponent,
    AppSvgIconComponent,
    OutlineTextInputComponent,
    BaseInputComponent,
  ],
  templateUrl: "./forgot-password.component.html",
  styleUrl: "./forgot-password.component.scss",
})
export class ForgotPasswordComponent extends BaseComponent implements OnInit {
  authService = inject(AuthService);
  formBuilder = inject(FormBuilder);

  forgotPasswordForm!: FormGroup;
  forgotPasswordState = new State<ForgotPasswordResponse>();

  ngOnInit(): void {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ["", [Validators.required]],
    });
  }

  onLoginClicked() {
    this.router.navigate(["/login"]);
  }

  onForgotPasswordClicked() {
    if (this.forgotPasswordForm.invalid) {
      return;
    }

    let request = this.forgotPasswordForm.value;
    this.executeRequest<ForgotPasswordResponse>({
      state: this.forgotPasswordState,
      request: this.authService.forgotPassword(request),
      onSuccess: (response) => {
        this.router.navigate(["/login"]);
      },
      handleSuccess: true,
      successMessage:
        "We will send you the reset password link to entered email.",
    });
  }
}
