<div class="p-4">
  <div class="flex flex-col gap-4 bg-white p-4 rounded-md">
    <div class="mx-auto py-4">
      <p class="p-2 text-body1 text-center">PROFICIENCY / ROAD TEST</p>
    </div>
    <div class="flex flex-col text-body1 text-neutral-800">
      <div> Instructions: If the road test is successfully completed, the person who gave it shall complete a certificate of the driver’s road test. The original or </div>
      <div> copy of the certificate shall be retained in the employing motor carrier’s driver qualification file of the person examined and a copy given to the</div>
      <div>person who was examined. <span class="text-info-500">(49 CFR 391.31</span>(e)(f)(g))</div>
    </div>
  </div>
</div>
