import { Component, inject, OnInit, output } from "@angular/core";
import { ButtonComponent } from "../../../../../shared/components/button/button.component";
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { OutlineTextInputComponent } from "../../../../../shared/inputs/outline-text-input/outline-text-input/outline-text-input.component";
import { RadioButtonComponent } from "../../../../../shared/inputs/radio-button/radio-button.component";
import { AppSvgIconComponent } from "../../../../../shared/components/app-svg-icon/app-svg-icon.component";
import { CheckboxComponent } from "../../../../../shared/inputs/checkbox/checkbox.component";
import { DropdownComponent } from "../../../../../shared/inputs/dropdown/dropdown.component";
import { BaseComponent } from "../../../../../shared/base/base-component";
import {
  GetDriverApplicationEmploymentHistoryItem,
  GetDriverApplicationEmploymentHistoryParams,
  GetDriverApplicationEmploymentHistoryResponse,
} from "../../../../../core/api/model";
import { TokenStoreService } from "../../../../../core/services/token/token-store.service";
import { State } from "../../../../../shared/base/base-state";
import { ApiService } from "../../../../../core/api/api.service";
import { DateUtils } from "../../../../../shared/utils/date-utils";
import { DriverApplicationService } from "../../../../../core/services/driver-application/driver-application.service";
import { SaveApplicantEmploymentRequest } from "../../../../../core/services/driver-application/save-applicant-employment/save-applicant-employment-request";

@Component({
  selector: "app-save-applicant-employments-info",
  standalone: true,
  imports: [
    ButtonComponent,
    FormsModule,
    OutlineTextInputComponent,
    RadioButtonComponent,
    ReactiveFormsModule,
    AppSvgIconComponent,
    CheckboxComponent,
    DropdownComponent,
  ],
  templateUrl: "./save-applicant-employments-info.component.html",
  styleUrl: "./save-applicant-employments-info.component.scss",
})
export class SaveApplicantEmploymentsInfoComponent extends BaseComponent implements OnInit {
  onNextClick = output<void>();
  onPreviousClick = output<void>();

  formBuilder = inject(FormBuilder);
  tokenService = inject(TokenStoreService);
  apiService = inject(ApiService);
  driverApplicationService = inject(DriverApplicationService);

  employmentsState = new State<GetDriverApplicationEmploymentHistoryResponse>();

  employmentForm!: FormGroup;
  employmentsFormArray!: FormArray;
  currentOrRecentEmploymentForm!: FormGroup;
  saveEmploymentState = new State<any>();

  ngOnInit() {
    this.initForm();
    this.getEmployments();
  }

  private initForm() {
    this.employmentsFormArray = this.formBuilder.array([]);
    this.employmentForm = this.formBuilder.group({
      employments: this.employmentsFormArray,
    });
  }

  getEmployments() {
    let request: GetDriverApplicationEmploymentHistoryParams = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
    };

    this.executeRequest<GetDriverApplicationEmploymentHistoryResponse>({
      state: this.employmentsState,
      request: this.apiService.getDriverApplicationEmploymentHistory(request),
      onSuccess: (response) => {
        let employments = response.data ?? [];
        this.populateForm(employments);
      },
    });
  }

  private populateForm(
    employments: GetDriverApplicationEmploymentHistoryItem[]
  ) {
    let currentOrMostRecentEmployment =
      employments.find((license) => license?.isCurrentOrMostRecentEmployer) ??
      null;
    let otherEmployers = employments.filter(
      (license) => !license?.isCurrentOrMostRecentEmployer
    );

    this.currentOrRecentEmploymentForm = this.createApplicantEmployment(
      currentOrMostRecentEmployment,
      true
    );
    this.employmentsFormArray.push(this.currentOrRecentEmploymentForm);

    otherEmployers.forEach((employer) => {
      let newForm = this.createApplicantEmployment(employer, false);
      this.employmentsFormArray.push(newForm);
    });
  }

  createApplicantEmployment(
    employment: GetDriverApplicationEmploymentHistoryItem | null,
    isCurrentOrMostRecentEmployment: boolean
  ) {
    let dateUtils = new DateUtils();
    let form = this.formBuilder.group({
      id: [employment?.id],
      name: [employment?.name, Validators.required],
      phone: [employment?.phone, Validators.required],
      address: [employment?.address, Validators.required],
      state: [employment?.state, Validators.required],
      positionHeld: [employment?.positionHeld, Validators.required],
      jobJoinDate: [
        dateUtils.convertToMMDDYYYY(employment?.jobJoinDate),
        Validators.required,
      ],
      jobEndDate: [
        dateUtils.convertToMMDDYYYY(employment?.jobEndDate),
        Validators.required,
      ],
      leavingReason: [employment?.leavingReason, Validators.required],
      salary: [employment?.salary],
      gapsExplain: [employment?.gapsExplain],
      fmcsrCompliance: [employment?.fmcsrCompliance, Validators.required],
      safetySensitiveTesting: [
        employment?.safetySensitiveTesting,
        Validators.required,
      ],
      isCurrentOrMostRecentEmployer: [isCurrentOrMostRecentEmployment],
    });
    return form;
  }

  onAddMoreEmploymentsClicked() {
    let newForm = this.createApplicantEmployment(null, false);
    this.employmentsFormArray.push(newForm);
  }

  onDeleteClicked(index: number): void {
    if (this.employmentsFormArray.length > 1)
      this.employmentsFormArray.removeAt(index);
  }

  onSaveAndNextClicked() {
    this.validateForm(this.employmentForm);
    if (this.employmentForm.invalid) {
      return;
    }

    let request: SaveApplicantEmploymentRequest = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
      employmentHistories: [],
    };

    let dateUtils = new DateUtils();
    this.employmentsFormArray.controls.forEach((control) => {
      let employment = control.value;
      request.employmentHistories.push({
        name: employment.name,
        phone: employment.phone,
        address: employment.address,
        state: employment.state,
        positionHeld: employment.positionHeld,
        jobJoinDate: dateUtils.convertToDateTimeString(employment.jobJoinDate)!,
        jobEndDate: dateUtils.convertToDateTimeString(employment.jobJoinDate)!,
        leavingReason: employment.leavingReason,
        salary: employment.salary,
        gapsExplain: employment.gapsExplain,
        fmcsrCompliance: employment.fmcsrCompliance,
        safetySensitiveTesting: employment.safetySensitiveTesting,
        isCurrentOrMostRecentEmployer: employment.isCurrentOrMostRecentEmployer,
      });
    });

    this.executeRequest({
      state: this.saveEmploymentState,
      request: this.driverApplicationService.saveApplicantEmployment(request),
      handleSuccess: true,
      handleError: true,
      showLoader: true,
      onSuccess: (response) => {
        this.onNextClick.emit();
      },
    });
  }

  onPreviousClicked() {
    this.onPreviousClick.emit();
  }
}
