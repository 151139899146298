<div class="flex justify-center container mx-auto h-full w-full p-4">
  <div class="flex flex-col bg-white w-full rounded-md">
    <div class="grow overflow-auto m-8 px-4 overflow-y-scroll no-scrollbar">
      <div class="py-6 space-y-4 text-center">
        <p class="text-body1">TO BE READ AND SIGNED BY APPLICANT</p>
      </div>
      <p class="text-body2 text-neutral-800 py-4">
        I authorize you to make investigations (including contacting current and prior employers) into my personal, employment, financial, medical history, and other related matters as may be necessary in arriving at an employment
        decision. I hereby release employers, schools, health care providers, and other persons from all liability in responding to inquiries and releasing information in connection with my application.
      </p>
      <p class="text-body2 text-neutral-800 py-4">
        In the event of employment, I understand that false or misleading information given in my application or interview(s) may result in discharge. I also understand that I am required to abide by all rules and regulations of the
        Company.
      </p>
      <p class="text-body2 text-neutral-800 py-4">
        I understand that the information I provide regarding my current and/or prior employers may be used, and those employer(s) will be contacted for the purpose of investigating my safety performance history as required by 49
        CFR 391.23. I understand that I have the right to:
      </p>
      <ul class="text-body2 text-neutral-800 py-4 list-disc mx-2">
        <li>Review information provided by current/previous employers;</li>
        <li>Have errors in the information corrected by previous employers, and for those previous employers to resend the corrected information to the prospective employer; and</li>
        <li>Have a rebuttal statement attached to the alleged erroneous information, if the previous employer(s) and I cannot agree on the accuracy of the information.</li>
      </ul>
      <p class="text-body2 text-neutral-800 py-4">
        This certifies that I completed this application, and that all entries on it and information in it are true and complete to the best of my knowledge. Note: A motor carrier may require an applicant to provide more information
        than that required by the Federal Motor Carrier Safety Regulations.
      </p>

      <form [formGroup]="completeApplicationForm" (ngSubmit)="onSubmitClicked()">
        <div class="flex flex-col gap-5">
          <app-file-uploader
            formControlName="dmvPrintoutFileUrl"
            label="Attach DMV Printout (Printout Date should be within 30 days of application date)"
            [isRequired]="true"
            [uploading]="uploadDmvPrintoutFileState.loading()"
            (fileSelected)="onDMVPrintoutFileSelected($event)"
          >
          </app-file-uploader>
          <app-file-uploader
            formControlName="licenseDotMedicalCertificateFileUrl"
            label="Attach current DOT medical certification (medical card)"
            [isRequired]="true"
            [uploading]="uploadDotMedicalCertificateFileState.loading()"
            (fileSelected)="onDotMedicalCertificatePrintoutFileSelected($event)"
          >
          </app-file-uploader>

          <app-file-uploader
            formControlName="currentDrivingLicenseFileUrl"
            label="Attach current driving license"
            [isRequired]="true"
            [uploading]="uploadCurrentDrivingLicenseFileState.loading()"
            (fileSelected)="onAttachCurrentDrivingLicenseFileSelected($event)"
          >
          </app-file-uploader>
        </div>
        <div class="flex items-center gap-3 py-4">
          <span>Are you in SAP (Substance abuse programme) Program?</span>
          <app-radio-button formControlName="isUnderSapProgram" groupName="isUnderSapProgram" title="Yes" [value]="true"></app-radio-button>
          <app-radio-button formControlName="isUnderSapProgram" groupName="isUnderSapProgram" title="No" [value]="false"></app-radio-button>
        </div>
        <div class="py-4">
          <div class="grid lg:grid-cols-2 gap-4">
            <app-outline-text-input formControlName="medicalCardExpirationDate" [label]="'Driving License Expiry Date'" placeholder="Ex: mm/dd/yyyy" mask="00/00/0000" class="w-full" [fullWidth]="true"></app-outline-text-input>
            <app-outline-text-input formControlName="medicalCardExpirationDate" [label]="'Medical Card Expiry Date'" placeholder="Ex: mm/dd/yyyy" mask="00/00/0000" class="w-full" [fullWidth]="true"></app-outline-text-input>
          </div>
        </div>

        <div class="grid lg:grid-cols-2 gap-4">
          <app-outline-text-input formControlName="applicantName" [label]="'Applicant Name'" placeholder="Ex: John" class="w-full" [fullWidth]="true"></app-outline-text-input>
          <app-outline-text-input formControlName="signDate" [label]="'Signature Date'" mask="00/00/0000" class="w-full" [fullWidth]="true"></app-outline-text-input>
        </div>
        <label class="text-body2 text-neutral-800">Signature</label>
        <app-signature-pad [width]="'500'" [height]="'200'" [backgroundColor]="'rgba(255, 255, 255, 0)'" [penColor]="'black'" (signatureChanged)="onSignatureSaved($event)" (signatureCleared)="onSignatureCleared()">
        </app-signature-pad>
        <div class="py-2">
          <app-button [fullWidth]="true" type="submit">Submit Application</app-button>
        </div>
      </form>
    </div>
  </div>
</div>
