<div class="container-fluid bg-neutral-500 bg-opacity-5">
  <div class="container mx-auto p-4">
    <div class="p-4 flex flex-col justify-center items-center space-y-4">
      <img height="40" src="../../../../../assets/images/branding/YSC-logo.png" />
      <p class="text-h6 text-primary-400 justify-center items-center">Please setup your profile</p>
    </div>
    <form [formGroup]="setupCompanyProfileForm" appInvalidControlScroll>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8 mb-36 md:mb-0">
        <div class="md:col-span-2 px-4 overflow-y-auto">
          <div class="bg-white p-4 rounded-lg my-4">
          <div class="py-8">
            <p class="text-body1 text-info-500">General Information</p>
            <p class="text-caption text-neutral-500">Please fill the general company information</p>
          </div>
          <div class="grid lg:grid-cols-2 gap-4">
            <app-outline-text-input formControlName="companyName" [label]="'Company Name / Individual Name'" placeholder="Ex: Mighty Mover Trucking" class="w-full" [fullWidth]="true"> </app-outline-text-input>
            <app-outline-text-input formControlName="dba" [label]="'Doing Business As'" placeholder="Ex: Quantum Roadways" class="w-full" [fullWidth]="true"></app-outline-text-input>
          </div>
          <div class="grid lg:grid-cols-2 gap-4">
            <div class="col-span-1">
              <app-dropdown
                formControlName="accountType"
                [title]="'Account Type'"
                [display]="'displayName'"
                placeholder="Company"
                [value]="'name'"
                [noDataMessage]="'No Options Available'"
                [fullWidth]="true"
                (valueChanged)="onAccountTypeChanged($event)"
                [items]="accountTypeState.response()?.data ?? []"
              >
              </app-dropdown>
            </div>

            @if (selectedAccountType?.name == "Individual") {
            <app-outline-text-input formControlName="ownerSsnNumber" [label]="'SSN Number'" placeholder="Ex: XXX-XX-XXXX" [fullWidth]="true" class="w-full"></app-outline-text-input>
            } @if (selectedAccountType?.name == "Company") {
            <app-outline-text-input formControlName="einNumber" [label]="'EIN Number'" placeholder="Ex: XXX-XX-XXXX" [fullWidth]="true" class="w-full"></app-outline-text-input>

            }
          </div>
          <div class="grid lg:grid-cols-2 gap-4">
            <app-outline-text-input formControlName="email" [label]="'Email'" placeholder="Ex: john.bravo@company.com" [fullWidth]="true" class="w-full"></app-outline-text-input>
            <app-outline-text-input formControlName="phoneNumber" [label]="'Phone Number'" placeholder="Ex: +44 912345678" [fullWidth]="true" class="w-full"></app-outline-text-input>
          </div>
          </div>
          <div class="p-4 bg-white rounded-lg my-4">
          <div class="py-8">
            <p class="text-body1 text-info-500">Business Address</p>
            <p class="text-caption text-neutral-500">Please fill the general company information</p>
          </div>
          <div class="grid lg:grid-cols-2 gap-4">
            <app-dropdown
              [title]="'Company Type'"
              [display]="'name'"
              [value]="'id'"
              formControlName="companyType"
              [noDataMessage]="'No Options Available'"
              [items]="companyTypesState.response()?.data ?? []"
              [fullWidth]="true"
              select="'name'"
            >
            </app-dropdown>
          </div>
          <div class="grid lg:grid-cols-2 gap-4">
            <app-outline-text-input formControlName="addressLine1" [label]="'Address Line 1'" placeholder="Ex: 42 Maple Avenue" class="w-full" [fullWidth]="true"></app-outline-text-input>
            <app-outline-text-input formControlName="addressLine2" [label]="'Address Line 2'" placeholder="Ex: Wing A" class="w-full" [fullWidth]="true"></app-outline-text-input>
            <app-dropdown
              formControlName="countryId"
              [title]="'Country'"
              [display]="'name'"
              [value]="'id'"
              [noDataMessage]="'No Options Available'"
              class="w-full"
              [fullWidth]="true"
              [items]="countryListState.response()?.data ?? [] "
              [items]="countryListState.response()?.data ?? []"
              (valueChanged)="onCountrySelected($event)"
            ></app-dropdown>
            <app-dropdown formControlName="stateId" [title]="'State'" [display]="'name'" [value]="'id'" [noDataMessage]="'No Options Available'" class="w-full" [fullWidth]="true" [items]="filteredStates"> </app-dropdown>
          </div>
          <div class="grid lg:grid-cols-2 gap-4">
            <app-outline-text-input formControlName="city" [label]="'City'" placeholder="Ex: Los Angeles" class="w-full" [fullWidth]="true"></app-outline-text-input>
            <app-outline-text-input formControlName="zipCode" [label]="'Zip code'" placeholder="Ex: 90001 " class="w-full" [fullWidth]="true"></app-outline-text-input>
          </div>
          </div>
          <div class="p-4 bg-white rounded-lg my-4">
          <div class="py-8">
            <p class="text-body1 text-info-500">Business Information</p>
            <p class="text-caption text-neutral-500">Please fill the general company information</p>
          </div>
          <div class="grid lg:grid-cols-2 gap-4">
            <app-dropdown
              formControlName="businessTypeId"
              [title]="'BusinessType'"
              [display]="'name'"
              [value]="'id'"
              [noDataMessage]="'No Options Available'"
              [fullWidth]="true"
              [items]="businessTypeState.response()?.data ?? []"
            >
            </app-dropdown>
            <app-dropdown
              formControlName="businessSubTypeId"
              [title]="'Business Sub Type'"
              [display]="'name'"
              [value]="'id'"
              [noDataMessage]="'No Options Available'"
              [fullWidth]="true"
              [items]="businessSubTypeState.response()?.data ?? []"
            ></app-dropdown>
          </div>
          <div class="grid lg:grid-cols-2 gap-4">
            <app-outline-text-input formControlName="businessRepresentativeFirstName" [label]="'Business Representative First Name'" placeholder="Ex: John" class="w-full" [fullWidth]="true"></app-outline-text-input>
            <app-outline-text-input formControlName="businessRepresentativeLastName" [label]="'Business Representative Last Name'" placeholder="Ex: Bravo" class="w-full" [fullWidth]="true"></app-outline-text-input>
          </div>
          <div class="grid lg:grid-cols-2 gap-4">
            <app-outline-text-input formControlName="dotNumber" [label]="'DOT#'" placeholder="Ex: DOT# 123456" class="w-full" [fullWidth]="true"></app-outline-text-input>
            <app-outline-text-input formControlName="caMcpNumber" [label]="'CA/MCP # (California only)'" placeholder="Ex: CA/MCP# 12345" class="w-full" [fullWidth]="true"></app-outline-text-input>
          </div>
          <div class="grid lg:grid-cols-2 gap-4">
            <app-dropdown
              formControlName="carrierType"
              [title]="'Carrier Type'"
              [display]="'displayName'"
              [value]="'name'"
              [noDataMessage]="'No Options Available'"
              [fullWidth]="true"
              (valueChanged)="onSelectedCarrierChanged($event)"
              [items]="carrierTypeState.response()?.data ?? []"
            ></app-dropdown>
            @if (selectedCarrierType?.name == "InterState") {
            <app-outline-text-input formControlName="mcNumber" [label]="'MC #'" placeholder="Ex: MC# 12345" class="w-full" [fullWidth]="true"></app-outline-text-input>

            }
          </div>
            <div class="p-4 gap-3">
              <span class="text-body2">Does your company transport hazardous materials (HAZMAT) in compliance with safety and regulatory standards?</span>
              <div class="py-2 flex items-center gap-3">
              <app-radio-button formControlName="transportsHazmat" [groupName]="'transportsHazmat'" title="Yes"
                                [value]="true"></app-radio-button>
              <app-radio-button formControlName="transportsHazmat" [groupName]="'transportsHazmat'" title="No"
                                [value]="false"></app-radio-button></div>
            </div>
            <div class="p-4 gap-3">
              <span class="text-body2">Does your company transport hazardous waste (HAZWASTE) in compliance with safety and regulatory standards?</span>
              <div class="py-2 flex items-center gap-3">
                <app-radio-button formControlName="transportsHazwaste" [groupName]="'transportsHazwaste'" title="Yes"
                                  [value]="true"></app-radio-button>
                <app-radio-button formControlName="transportsHazwaste" [groupName]="'transportsHazwaste'" title="No"
                                  [value]="false"></app-radio-button></div>
            </div>
        </div>
        </div>
        <div class="grid lg:grid-cols-2 mx-4 space-x-4">
          <div class="hidden md:block col-span-1 md:col-span-1">
            <div class="md:fixed md:top-20 bg-white">
              <div class="md:block flex flex-col px-6 py-6 space-y-4 bg-none items-center shadow-2">
                <div class="space-y-4">
                  <p class="text-body1">Need help?</p>
                  <p class="text-body2 text-neutral-500">We will be happy to assist you in filling this form.</p>
                  <p class="text-body2 text-neutral-500">Please contact our management.</p>
                  <p class="text-body1 text-neutral-500">+1 (209)-200-8308</p>
                  <p class="text-body1 text-info-500">{{ 'info@yoursafetycompliance.com' }}</p>
                </div>
                <div class="space-y-4">
                  <app-button class="space-y-4" type="submit" (click)="onClickCompleteProfile()" [fullWidth]="true" [loading]="setCompanyProfileState.loading()">Complete Profile </app-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="block md:hidden fixed bottom-0 w-full bg-white shadow-1">
  <div class="flex flex-col gap-5 p-4">
    <p class="text-body1">Need help?</p>
    <div class="flex gap-4 justify-between">
      <p class="text-body1 text-neutral-500">+1 (209)-200-8308</p>
      <p class="text-body1 text-info-500">{{ 'info@yoursafetycompliance.com' }}</p>
    </div>
    <app-button class="space-y-4" type="submit" (click)="onClickCompleteProfile()" [fullWidth]="true" [loading]="setCompanyProfileState.loading()">Complete Profile </app-button>
  </div>
</div>
