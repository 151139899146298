import { Component } from '@angular/core';

@Component({
  selector: 'app-proficiency-test',
  standalone: true,
  imports: [],
  templateUrl: './proficiency-test.component.html',
  styleUrl: './proficiency-test.component.scss'
})
export class ProficiencyTestComponent {

}
