import { Component, inject, OnInit } from "@angular/core";
import { AppSvgIconComponent } from "../../../../shared/components/app-svg-icon/app-svg-icon.component";
import { BaseTableComponent } from "../../../../shared/components/_base/base-table/base-table.component";
import { ButtonComponent } from "../../../../shared/components/button/button.component";
import { MatMenu, MatMenuItem } from "@angular/material/menu";
import { OutlineTextInputComponent } from "../../../../shared/inputs/outline-text-input/outline-text-input.component";
import { SortableTableDirective } from "../../../../shared/components/_base/base-table/sortable-table.directive";
import { TableResizableColumnsDirective } from "../../../../shared/components/_base/base-table/table-resizable-columns.directive";
import { ExpiringDocumentsCardComponent } from "../../../dashboard/pages/expiring-documents-card/expiring-documents-card.component";
import { OverviewCardComponent } from "../../../dashboard/pages/overview-card/overview-card.component";
import { FileUploaderComponent } from "../../../../shared/inputs/file-uploader/file-uploader.component";
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { RadioButtonComponent } from "../../../../shared/inputs/radio-button/radio-button.component";
import { BaseComponent } from "../../../../shared/base/base-component";
import { CheckboxComponent } from "../../../../shared/inputs/checkbox/checkbox.component";
import { ApplicantLicensesInfoResponse } from "../../../../core/services/driver-application/applicant-licenses-info/applicant-licenses-info-response";
import { State } from "../../../../shared/base/base-state";
import { AuthService } from "../../../../core/services/auth/auth.service";
import { CompanyInfoResponse } from "../../../../core/services/auth/company-info/company-type-response";
import { LookupService } from "../../../../core/services/lookup/lookup.service";
import { DropdownComponent } from "../../../../shared/inputs/dropdown/dropdown.component";

@Component({
  selector: "app-hire-driver",
  standalone: true,
  imports: [
    AppSvgIconComponent,
    BaseTableComponent,
    ButtonComponent,
    MatMenu,
    MatMenuItem,
    OutlineTextInputComponent,
    SortableTableDirective,
    TableResizableColumnsDirective,
    ExpiringDocumentsCardComponent,
    OverviewCardComponent,
    FileUploaderComponent,
    FormsModule,
    ReactiveFormsModule,
    RadioButtonComponent,
    CheckboxComponent,
    DropdownComponent,
  ],
  templateUrl: "./hire-driver.component.html",
  styleUrl: "./hire-driver.component.scss",
})
export class HireDriverComponent extends BaseComponent implements OnInit {
  formBuilder = inject(FormBuilder);
  driverHiringForm!: FormGroup;
  companyInfoState = new State<CompanyInfoResponse>();
  lookupService = inject(LookupService);

  ngOnInit() {
    this.initForm();
    this.getCompanyInfo();
  }

  private initForm() {
    this.driverHiringForm = this.formBuilder.group({
      preEmploymentClearingHouse: ["", Validators.required],
      drugAlcoholPolicy: ["", Validators.required],
      drugTestResultAttachment: ["", Validators.required],
      drugTestCCF: ["", Validators.required],
      drugTestResult: [false, Validators.required],
      mroVerificationDate: ["", Validators.required],
      startSapProgram: [false, Validators.required],
      returnToDutyDocument: [""],
      attachDrugTestCCF: ["", Validators.required],
      clearingHouseQueryPolicy: ["", Validators.required],
      clearingHouseLimitedQueryExpiryDate: ["", Validators.required],
      dmvPrintout: ["", Validators.required],
      screenShotOfNationalRegistryReference: ["", Validators.required],
      employerPullNotice: [""],
      hasSPABCertificate: [true, Validators.required],
      hazMat: [true, Validators.required],
      hazMatCertificate: ["", Validators.required],
      confirmationOfRequirements: ["", Validators.required],
      clearingHouseLimitedQueryPolicy: ["", Validators.required],
      motorVehicleRecord: ["", Validators.required],
      medicalExaminerCertificate: ["", Validators.required],
      proficiencyTestResult: ["", Validators.required],
      spabCertificate: ["", Validators.required],
      t1Certificate: [""],
      t2Certificate: [""],
      medicalExaminerCertificateExpirationDate: ["", Validators.required],
      fullClearingHouseQueryCompletedDate: ["", Validators.required],
      fullClearingHouseQueryUploadFrequency: ["", Validators.required],
    });
  }

  onStartProficiencyTest() {
    this.router.navigate(["/proficiency-test"]);
  }

  getCompanyInfo() {
    this.executeRequest<CompanyInfoResponse>({
      state: this.companyInfoState,
      request: this.lookupService.getCompanyInfo(),
    });
  }
}
