<div class="container p-4 mx-auto flex flex-col gap-4">
  <div class="flex gap-5 justify-end items-center ml-auto">
    <div class="flex gap-2 items-center border text-primary-500 rounded-lg">
      <app-button [iconSrc]="'assets/icons/export.svg'" [buttonColor]="'bg-white'" [appearance]="'outline'"
                  [textButtonColor]="'text-primary-500'">Export
      </app-button>
    </div>
    <div class="flex gap-2 items-center bg-primary-500 rounded-lg">
      <app-button [iconSrc]="'assets/icons/plus.svg'" [matMenuTriggerFor]="menu">New Driver</app-button>
      <mat-menu #menu="matMenu" class="mat-elevation-z3 mt-4">
        <button mat-menu-item class="flex items-center" (click)="onSendDriverApplicationClicked()">
          <div class="flex items-center gap-2">
            <app-svg-icon class="text-neutral-400" [src]="'assets/icons/share.svg'"></app-svg-icon>
            <p class="text-body2">Send Driver Application</p>
          </div>
        </button>
        <button mat-menu-item class="flex items-center">
          <div class="flex items-center gap-2">
            <app-svg-icon class="text-neutral-400" [src]="'assets/icons/download.svg'"></app-svg-icon>
            <p class="text-body2">Download Application</p>
          </div>
        </button>
        <button mat-menu-item class="flex items-center">
          <div class="flex items-center gap-2">
            <app-svg-icon class="text-neutral-400" [src]="'assets/icons/plus.svg'"></app-svg-icon>
            <p class="text-body2">New Driver</p>
          </div>
        </button>
      </mat-menu>
    </div>
  </div>

  <div class="flex gap-2">
    <app-outline-text-input [iconSrc]="'assets/icons/magnify.svg'" placeholder="Search..." type="search"
                            [fullWidth]="false"></app-outline-text-input>
  </div>

  <app-base-table [state]="driverListState">
    <thead head>
    <tr class="border-b border-neutral-200 text-overline text-neutral-500 text-nowrap">
      <th class="p-6 text-left w-1/7">FULL NAME</th>
      <th class="p-6 text-left w-1/7">CONTACT</th>
      <th class="p-6 text-center w-1/7">HIRED DATA</th>
      <th class="p-6 text-center w-1/7">LICENSE EXPIRING</th>
      <th class="p-6 text-center w-1/7">STATUS</th>
      <th class="p-6 text-center w-1/7"></th>
    </tr>
    </thead>
    <tbody body>
      @for (item of driverListState.response()?.data; track item; ) {
        <tr class="border-b border-neutral-100 w-full">
          <td class="p-4 text-body2 text-left text-info-500">{{ item.fullName }}</td>
          <td class="p-4 text-body2 text-center">{{ item.phoneNumber }}</td>
          <td class="p-4 text-body2 text-center">{{ item.hiredDate }}</td>
          <td class="p-4 text-body2 text-center">{{ item.licenseExpiringDate }}</td>
          <td class="p-4">
            <div
              class="flex bg-info-50 rounded-full h-12 w-full items-center justify-center text-info-500 text-nowrap"
              [ngClass]="{
                      'bg-info-50 text-info-500': item.driverStatus === 'Hired',
                      'bg-red-50 text-error-500' : item.driverStatus === 'Rejected',
                      'bg-yellow-50 text-yellow-500' : item.driverStatus === 'Application Started',
                    }"
            >
              {{ item.driverStatus }}
            </div>
          </td>
          <td class="p-4 cursor-pointer items-center">
            <div class="flex justify-center items-center">
              <app-context-menu-icon></app-context-menu-icon>
            </div>
          </td>
        </tr>
      }
    </tbody>
  </app-base-table>

</div>
