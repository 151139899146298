import {AfterViewInit, Component, input, output, signal} from '@angular/core';
import {State} from "../../../base/base-state";
import {NoDataTableComponent} from "../../no-data-table/no-data-table.component";
import {ShimmerComponent} from "../../shimmer/shimmer.component";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {NgClass} from "@angular/common";

@Component({
  selector: 'app-base-table',
  standalone: true,
  imports: [
    NoDataTableComponent,
    ShimmerComponent,
    MatPaginator,
    NgClass
  ],
  templateUrl: './base-table.component.html',
  styleUrl: './base-table.component.scss'
})
export class BaseTableComponent<T> implements AfterViewInit {
  pageSize = input(10);
  state = input<State<any>>();
  isHorizontallyScrollable = input(false);

  pageChange = output<PagingEvent>();

  ngAfterViewInit(): void {
    this.pageChange.emit({pageNumber: 1, pageSize: this.pageSize()});
  }

  onPageChange(event: PageEvent) {
    const newPageNumber = event.pageIndex + 1;
    this.pageChange.emit({pageNumber: newPageNumber, pageSize: this.pageSize()});
  }

}

export interface PagingEvent {
  pageNumber: number;
  pageSize: number;
}
