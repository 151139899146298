import { inject, Injectable, signal } from "@angular/core";
import { Router } from "@angular/router";
import { jwtDecode, JwtPayload } from "jwt-decode";

@Injectable({
  providedIn: "root",
})
export class TokenStoreService {
  router = inject(Router);

  storage: Storage;

  private static REFRESH_TOKEN_KEY = "refresh_token";
  private static ACCESS_TOKEN_KEY = "access_token";
  private static DRIVER_APPLICATION_TOKEN = "driver_application_token";
  private static DRIVER_APPLICATION_ID = "driver_application_id";

  isLoggedIn = signal<boolean>(false);

  constructor() {
    this.storage = localStorage;
    const token = this.getToken();
    if (token.refreshToken && token.accessToken) {
      this.isLoggedIn.update(() => true);
    } else {
      this.isLoggedIn.update(() => false);
    }
  }

  saveToken(refreshToken: string, accessToken: string) {
    this.storage.setItem(TokenStoreService.REFRESH_TOKEN_KEY, refreshToken);
    this.storage.setItem(TokenStoreService.ACCESS_TOKEN_KEY, accessToken);
    this.isLoggedIn.update(() => true);
  }

  getToken() {
    return {
      refreshToken: this.storage.getItem(TokenStoreService.REFRESH_TOKEN_KEY),
      accessToken: this.storage.getItem(TokenStoreService.ACCESS_TOKEN_KEY),
    };
  }

  saveDriverApplicationToken(token: string | undefined) {
    if (token) {
      this.storage.setItem(TokenStoreService.DRIVER_APPLICATION_TOKEN, token);
    }
  }

  getDriverApplicationToken() {
    return this.storage.getItem(TokenStoreService.DRIVER_APPLICATION_TOKEN);
  }

  saveDriverApplicationId(driverApplicationId: string) {
    this.storage.setItem(TokenStoreService.DRIVER_APPLICATION_ID, driverApplicationId);
  }

  getDriverApplicationId(): string {
    let driverToken = this.getDriverApplicationToken();
    if (driverToken == null) {
      let driverApplicationId = this.storage.getItem(TokenStoreService.DRIVER_APPLICATION_ID);
      return driverApplicationId ?? '';
    }

    try {
      const decoded = jwtDecode<any>(driverToken);
      return decoded.id;
    } catch (error) {
      return '';
    }
  }

  isValidJWTToken(token: string | undefined) {
    try {
      jwtDecode<JwtPayload>(token ?? "");
      return true;
    } catch (error) {
      return false;
    }
  }

  logout() {
    this.isLoggedIn.set(false);
    this.storage.removeItem(TokenStoreService.REFRESH_TOKEN_KEY);
    this.storage.removeItem(TokenStoreService.ACCESS_TOKEN_KEY);
    this.router.navigate(["/"]);
  }
}
