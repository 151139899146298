import {Component, inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {BaseComponent} from "../../../../shared/base/base-component";
import {
  OutlineTextInputComponent
} from "../../../../shared/inputs/outline-text-input/outline-text-input.component";
import {SignaturePadComponent} from "../../../../core/components/signature-pad/signature-pad.component";
import {CheckboxComponent} from "../../../../shared/inputs/checkbox/checkbox.component";
import {ButtonComponent} from "../../../../shared/components/button/button.component";
import {State} from "../../../../shared/base/base-state";

@Component({
  selector: 'app-proficiency-test',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    OutlineTextInputComponent,
    SignaturePadComponent,
    CheckboxComponent,
    ButtonComponent
  ],
  templateUrl: './proficiency-test.component.html',
  styleUrl: './proficiency-test.component.scss'
})
export class ProficiencyTestComponent extends BaseComponent implements OnInit{
formBuilder = inject(FormBuilder);
proficiencyTestForm!: FormGroup;
proficiencyTestState= new State<any>();

ngOnInit() {
  this.initForm();
}

private initForm()
{
this.proficiencyTestForm = this.formBuilder.group({
  driversName: ["",Validators.required],
  cdl:["",Validators.required],
  state:["",Validators.required],
  powerUnit:["",Validators.required],
  trailer:["",Validators.required],
  date:["",Validators.required],
  distanceDriven:["",Validators.required],
  examinerFirstName:["",Validators.required],
  examinerLastName:["",Validators.required],
  dateofApplication:["",Validators.required],
  signature:["",Validators.required],
  certifyDriver:["",Validators.required],
});
}

  onCompleteTest()
  {

  }
}
