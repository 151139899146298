import { Component, OnInit, inject } from "@angular/core";
import { OutlineTextInputComponent } from "../../../../shared/inputs/outline-text-input/outline-text-input.component";
import { ButtonComponent } from "../../../../shared/components/button/button.component";
import { AppSvgIconComponent } from "../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { State } from "../../../../shared/base/base-state";
import { BaseComponent } from "../../../../shared/base/base-component";
import { BaseInputComponent } from "../../../../shared/inputs/_base/base-input/base-input.component";
import { DropdownComponent } from "../../../../shared/inputs/dropdown/dropdown.component";
import { RouterLink } from "@angular/router";
import { LookupService } from "../../../../core/services/lookup/lookup.service";
import { CompanyTypesRequest } from "../../../../core/services/lookup/company-types/company-types-request";
import { CompanyTypesResponse } from "../../../../core/services/lookup/company-types/company-types-response";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { SignupSuccessComponent } from "./signup-success/signup-success.component";
import { AuthService } from "../../../../core/services/auth/auth.service";

@Component({
  selector: "app-sign-up",
  standalone: true,
  templateUrl: "./sign-up.component.html",
  styleUrl: "./sign-up.component.scss",
  imports: [
    OutlineTextInputComponent,
    ButtonComponent,
    AppSvgIconComponent,
    ReactiveFormsModule,
    BaseInputComponent,
    DropdownComponent,
    RouterLink,
    MatDialogModule,
    SignupSuccessComponent,
  ],
})
export class SignUpComponent extends BaseComponent implements OnInit {
  formBuilder = inject(FormBuilder);
  authService = inject(AuthService);
  lookupService = inject(LookupService);
  readonly dialog = inject(MatDialog);

  signUpForm!: FormGroup;

  companyTypesState = new State<CompanyTypesResponse>();
  signUpState = new State<any>();

  ngOnInit(): void {
    this.initForm();
    this.getCompanyTypes();
  }

  initForm() {
    this.signUpForm = this.formBuilder.group({
      firstName: ["", Validators.required],
      lastName: [""],
      companyType: ["", Validators.required],
      phoneNumber: ["", Validators.required],
      email: ["", Validators.email],
      companyName: ["", Validators.required],
      dotNumber: ["", Validators.required],
    });
  }

  getCompanyTypes() {
    this.executeRequest<CompanyTypesResponse>({
      state: this.companyTypesState,
      request: this.lookupService.getCompanyTypes(),
    });
  }

  openDialogue() {
    this.dialog.open(SignupSuccessComponent, {
      width: "1000px",
      height: "330px",
    });
  }

  onSignUpClicked() {
    console.log(this.signUpForm.value);
    if (this.signUpForm.invalid) {
      return;
    }
    let request = this.signUpForm.value;
    this.executeRequest({
      state: this.signUpState,
      request: this.authService.signup(request),
      onSuccess: (response) => {
        this.signUpForm.reset();
        this.openDialogue();
      },
    });
  }

  onLoginClicked() {
    this.router.navigate(["/login"]);
  }
}
