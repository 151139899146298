import { Injectable } from '@angular/core';
import {SendDriverApplicationRequest} from "./send-driver-application/send-driver-application.request";
import {SendDriverApplicationResponse} from "./send-driver-application/send-driver-application-response";
import {URLs} from "../../api/urls";
import {BaseApiService} from "../../../shared/base/base-api.service";

@Injectable({
  providedIn: 'root'
})
export class DriverService extends BaseApiService {

  sendDriverApplication(request: SendDriverApplicationRequest) {
    return this.post<SendDriverApplicationResponse>(URLs.sendDriverApplication, request);
  }
}
