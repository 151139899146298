<div class="p-4">
  <div class="flex flex-col gap-4 bg-white p-4 rounded-md">
    <div class="mx-auto py-4">
      <p class="p-2 text-body1 text-center">HIRE APPLICANT</p>
      <p class="p-2 text-body1 text-center">Please upload the required documents to complete the hiring process</p>
    </div>
    <form [formGroup]="driverHiringForm">
    <div class="flex flex-col p-6 gap-6">
    <app-file-uploader
      formControlName="clearingHouseQuery"
      label="Attach Full Clearinghouse query"
      [isRequired]="true"
    ></app-file-uploader>
      <app-file-uploader
        formControlName="drugTestResultAttachment"
        label="Attach drug test result"
        [isRequired]="true"
      ></app-file-uploader>

      <div class="flex flex-col py-4 gap-3">
        <span class="text-primary-600">Please provide the drug test result</span>
        <div class="flex gap-3">
          <app-radio-button
            formControlName="drugTestResult"
            groupName="drugTestResult"
            title="Negative"
            [value]="true">
          </app-radio-button>
          <app-radio-button
            formControlName="drugTestResult"
            groupName="drugTestResult"
            title="Positive"
            [value]="false">
          </app-radio-button>
        </div>
      </div>
      <div class="flex flex-col py-4 gap-3">
        <span class="text-primary-600">Do you want to start the SAP programme for this applicant?</span>
        <div class="flex gap-3">
          <app-radio-button
            formControlName="startSapProgram"
            groupName="startSapProgram"
            title="Yes"
            [value]="true">
          </app-radio-button>
          <app-radio-button
            formControlName="startSapProgram"
            groupName="startSapProgram"
            title="No"
            [value]="false">
          </app-radio-button>
        </div>
        <div class="flex items-center gap-3">
          <p class="text-body1">Please provide the drug test result (Optional)</p>
          <div class="flex items-center border border-success-500 p-2 m-2 cursor-pointer">
            <app-svg-icon src="assets/icons/menu-right.svg" class="text-success-500" [size]="32"></app-svg-icon>
            <a class="text-success-500 text-body1" (click)="onStartProficiencyTest()">Start Proficiency Test (Road Test)</a>
          </div>
        </div>
        <app-checkbox formControlName="confirmationOfRequirements"
                      title="I confirm that all details provided by the job applicant have been verified, and the candidate meets the necessary requirements.">
        </app-checkbox>
      </div>
      <div class="grid grid-cols-2 gap-3">
        <app-button [fullWidth]="true" [textButtonColor]="'text-error-500'" [outlineColor]="'border-error-500'"[appearance]="'outline'">Reject Job Application</app-button>
        <app-button [fullWidth]="true">Hire Applicant</app-button>
      </div>
    </div>
    </form>
  </div>
</div>
