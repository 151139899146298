<div class="mx-4 p-6">
  <div class="flex flex-col gap-4 bg-white p-4 rounded-md">
    <div class="mx-auto py-4">
      <p class="p-2 text-body1 text-center">PROFICIENCY / ROAD TEST</p>
    </div>
    <div class="w-full px-4 py-6 text-body1 text-neutral-800">
      Instructions: If the road test is successfully completed, the person who gave it shall complete a certificate of the driver’s road test.
      The original or copy of the certificate shall be retained in the employing motor carrier’s driver qualification file of the person examined and a copy given to the person who was examined.
      <span class="text-info-500">(49 CFR 391.31(e)(f)(g))</span>
    </div>

    <form [formGroup]="proficiencyTestForm" (ngSubmit)="onCompleteTest()" class="p-4" >
      <div class="grid lg:grid-cols-2 gap-4">
        <app-outline-text-input formControlName="driversName" [label]="'Driver’s name'" placeholder="Ex: John" class="w-full" [fullWidth]="true"> </app-outline-text-input>
        <app-outline-text-input formControlName="cdl" [label]="'CDL'" placeholder="Ex: XXX XXX XXXX" class="w-full" [fullWidth]="true"> </app-outline-text-input>
      </div>
      <div class="grid lg:grid-cols-2 gap-4">
        <app-outline-text-input formControlName="state" [label]="'State'" placeholder="Ex: California" class="w-full" [fullWidth]="true"> </app-outline-text-input>
        <app-outline-text-input formControlName="powerUnit" [label]="'Power Unit'" placeholder="Ex: Truck" class="w-full" [fullWidth]="true"> </app-outline-text-input>
      </div>
      <div class="grid lg:grid-cols-2 gap-4">
        <app-outline-text-input formControlName="trailer" [label]="'Trailer'" placeholder="Ex: Trailer" class="w-full" [fullWidth]="true"> </app-outline-text-input>
        <app-outline-text-input formControlName="date" [label]="'Date'" [mask]="'00/00/0000'" class="w-full" [fullWidth]="true"> </app-outline-text-input>
      </div>
      <div class="py-4">
      <div class="line"></div>
      </div>
      <div class="flex items-center gap-3 w-full">
        <app-outline-text-input formControlName="distanceDriven" [label]="'Distance Driven (in miles)'" placeholder="Ex: 3500" class="w-full" [fullWidth]="true"></app-outline-text-input>
      </div>
      <div class="grid lg:grid-cols-2 gap-4">
        <app-outline-text-input formControlName="examinerFirstName" [label]="'Examiner first name'" placeholder="Ex: John" class="w-full" [fullWidth]="true"> </app-outline-text-input>
        <app-outline-text-input formControlName="examinerLastName" [label]="'Examiner Last name'" placeholder="Ex: Bravo" class="w-full" [fullWidth]="true"> </app-outline-text-input>
      </div>
      <div class="grid lg:grid-cols-2 gap-4">
        <app-outline-text-input formControlName="dateofApplication" [label]="'Date'" [mask]="'00/00/0000'" class="w-full" [fullWidth]="true"> </app-outline-text-input>
      </div>
        <label class="text-body2 text-neutral-800">Signature</label>
        <app-signature-pad [width]="'500'" [height]="'200'" [backgroundColor]="'rgba(255, 255, 255, 0)'" [penColor]="'black'">
        </app-signature-pad>
      <div class="grid lg:grid-cols-2 gap-4 py-4">
      <app-checkbox class="col-span-2" formControlName="certifyDriver"
                    title="This is to certify that the above-named driver was given a road test under my supervision. It is my considered opinion that this driver possesses sufficient driving skill to safely operate the type of commercial motor vehicle listed above.">
      </app-checkbox>
  </div>
        <app-button class="my-2" [fullWidth]="true" type="submit" [loading]="proficiencyTestState.loading()">COMPLETE TEST</app-button>
    </form>
  </div>
</div>
