import {Injectable} from '@angular/core';
import {BaseApiService} from "../../../shared/base/base-api.service";

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends BaseApiService {

  getOverviewData() {
    return this.fromFile('assets/json/mock-data/dashboard-overview-response.json');
  }

  getExpiringDocuments() {
    return this.fromFile('assets/json/mock-data/expiring-documents-response.json');
  }
}
