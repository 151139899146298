import {
  AfterContentInit, AfterViewInit, ChangeDetectorRef,
  Component, ElementRef, HostListener,
  inject,
  input,
  output, Renderer2,
  signal, ViewChild
} from '@angular/core';
import {AppSvgIconComponent} from "../../components/app-svg-icon/app-svg-icon.component";
import {FormControl, NgControl, ReactiveFormsModule} from "@angular/forms";
import {ClickOutsideDirective} from '../../directives/click-outside.directive';
import {BaseInputComponent} from '../_base/base-input/base-input.component';
import {State} from '../../base/base-state';
import {BaseControlValueAccessor} from '../../base/base-control-value-accessor';
import {NgClass, NgStyle} from "@angular/common";
import {HumanizeFormMessagesPipe} from "../humanize-form-messages.pipe";
import {deepEqual} from "../_utils/base-input-utils";

@Component({
  selector: 'app-dropdown',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    ReactiveFormsModule,
    ClickOutsideDirective,
    BaseInputComponent,
    NgClass,
    HumanizeFormMessagesPipe,
    NgStyle
  ],
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.scss'
})
export class DropdownComponent<T> extends BaseControlValueAccessor implements AfterContentInit, AfterViewInit {

  cdr = inject(ChangeDetectorRef);
  renderer = inject(Renderer2);
  elementRef = inject(ElementRef);


  title = input<string | null>();
  items = input<T[]>([]);
  placeholder = input<string>('Select');
  display = input.required<string | null>();
  value = input<string>('');
  // identifier = input<string>();
  noDataMessage = input<string>();
  state = input<State<any>>();
  fullWidth = input<boolean>(false);
  isOpen = signal(false);
  selectedOption: T | null = null;
  valueChanged = output<T | null>();
  errorMessages = signal<{ [key: string]: string }>({});
  ngControl = inject(NgControl, {optional: true, self: true});
  showErrorSpace = input<boolean>(false);

  highlightedIndex = signal(-1);

  selectedItem = signal<T | null>(null);

  dropUp = signal(false);

  parentWidth = signal('0px');

  @ViewChild('dropdownButton', {static: true}) dropdownButton!: ElementRef;
  @ViewChild('dropdownList', {static: false}) dropdownList!: ElementRef;

  constructor() {
    super();
    if (this.ngControl) {
      this.ngControl!.valueAccessor = this;
    }
  }

  ngAfterContentInit(): void {
    let formControl = this.ngControl?.control as FormControl;
    if (formControl) {
      this.formControl = this.ngControl?.control as FormControl;
    }
  }

  ngAfterViewInit() {
    let width = this.dropdownButton.nativeElement.parentElement.offsetWidth;
    this.parentWidth.set(`${width}px`);
  }

  getProperty(item: T | null): any {
    if (this.display() == null || this.display() == '') {
      return item;
    }

    let alreadySelectedValue = this.findValue(this.items(), this.value(), this.formControl.value);

    let object = item as any;
    if (alreadySelectedValue) {
      object = alreadySelectedValue;
    }

    let value = this.display()!.split('.').reduce((acc, part) => acc && acc[part], object);
    return value;
  }

  getDisplayString(item: T): any {
    if (this.display() == null || this.display() == '') {
      return item;
    }
    let object = item as any;
    return this.display()!.split('.').reduce((acc, part) => acc && acc[part], object);
  }

  findValue(items: any, value: any, searchValue: any) {
    if (searchValue == null || searchValue == "") {
      return;
    }
    return items.find((item: any) => this.getValueFromObject(item, value) === searchValue);
  }

  getValueFromObject(object: any, path: string) {
    return path.split('.').reduce((acc, part) => acc && acc[part], object);
  }

  getValue(item: T): any {
    if (this.value() == null || this.value() == '') {
      return item;
    }
    let object = item as any;
    return this.value()!.split('.').reduce((acc, part) => acc && acc[part], object);
  }

  getSelectedItem(): T | null {
    let items = this.items() ?? [];
    let selectedItem = items.find(item => this.getValue(item) == this.formControl.value);
    return selectedItem || null;
  }

  // getIdentifier(item: T): any {
  //   if (!this.identifier()) {
  //     return item;
  //   }
  //   let object = item as any;
  //   let identifier = this.identifier()!.split('.').reduce((acc, part) => acc && acc[part], object);
  //   return identifier;
  // }

  // isItemSelected(item: T): boolean {
  //   const controlValue = this.formControl.value;
  //   let identifierPath = this.identifier()!;
  //   if (identifierPath == null || identifierPath == '') {
  //     return false;
  //   }
  //
  //   if(controlValue == item) {
  //     return true;
  //   }
  //
  //   const propertyValue = identifierPath.split('.').reduce((acc, part) => acc && acc[part], item as any);
  //
  //   let isEqual = propertyValue === this.getIdentifier(controlValue);
  //   console.log('Property Value', propertyValue);
  //   console.log('Identifier', propertyValue);
  //   return isEqual;
  // }

  toggleDropdown(): void {
    this.isOpen.update(prev => !prev);
    if (this.isOpen()) {
      let index = this.items().findIndex(item => item == this.selectedItem());
      this.highlightedIndex.set(index);
      this.adjustDropdownPosition();

      this.cdr.detectChanges();
      this.setDropdownMaxHeight();

      this.scrollToHighlightedItem();
    }
  }

  onClickOutside() {
    this.isOpen.set(false);
  }

  optionSelected(item: T): void {
    this.selectedItem.set(item);
    if (this.formControl.enabled) {
      this.markAsTouched();
      const value = this.getValue(item);
      if (value == this.formControl.value) {
        this.onChange(value);
        this.valueChanged.emit(null);
      } else {
        this.onChange(value);
        this.valueChanged.emit(item);
      }
    }
  }

  setDropdownMaxHeight() {
    const buttonRect = this.dropdownButton.nativeElement.getBoundingClientRect();
    const spaceBelow = window.innerHeight - buttonRect.bottom;
    const spaceAbove = buttonRect.top;

    let maxHeight;
    if (this.dropUp()) {
      maxHeight = spaceAbove - 36;
    } else {
      maxHeight = spaceBelow - 36;
    }

    if (this.dropdownList) {
      this.renderer.setStyle(this.dropdownList.nativeElement, 'max-height', `${maxHeight}px`);
    }
  }

  adjustDropdownPosition() {
    const buttonRect = this.dropdownButton.nativeElement.getBoundingClientRect();
    const spaceBelow = window.innerHeight - buttonRect.bottom;
    const spaceAbove = buttonRect.top;
    this.dropUp.set(spaceAbove > spaceBelow && spaceBelow < 200);
  }

  scrollToHighlightedItem() {
    if (this.dropdownList && this.dropdownList.nativeElement.children[this.highlightedIndex()]) {
      const highlightedItem = this.dropdownList.nativeElement.children[this.highlightedIndex()];
      highlightedItem.scrollIntoView({block: 'nearest'});
    }
  }

  @HostListener('window:resize')
  onResize() {
    if (this.isOpen()) {
      this.adjustDropdownPosition();
    }
  }

  handleKeydown(event: KeyboardEvent) {
    if (!this.isOpen()) {
      return;
    }

    switch (event.key) {
      case 'ArrowDown':
        if (this.highlightedIndex() < this.items().length - 1) {
          this.highlightedIndex.update(prev => prev + 1);
        }
        this.scrollToHighlightedItem();
        event.preventDefault();
        break;
      case 'ArrowUp':
        if (this.highlightedIndex() > 0) {
          this.highlightedIndex.update(prev => prev - 1);
        }
        this.scrollToHighlightedItem();
        event.preventDefault();
        break;
      case 'Enter':
        this.optionSelected(this.items()[this.highlightedIndex()]);
        this.isOpen.set(false);
        event.preventDefault();
        break;
      case 'Escape':
        this.isOpen.set(false);
        event.preventDefault();
        break;
    }
  }
}
