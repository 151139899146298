import { Component, Input, inject, signal, OnInit } from "@angular/core";
import { OutlineTextInputComponent } from "../../../../shared/inputs/outline-text-input/outline-text-input/outline-text-input.component";
import { ButtonComponent } from "../../../../shared/components/button/button.component";
import {
  EmailValidator,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { State } from "../../../../shared/base/base-state";
import { BaseComponent } from "../../../../shared/base/base-component";
import { ReactiveFormsModule } from "@angular/forms";
import { AuthService } from "../../../../core/services/auth/auth.service";
import { ResetPasswordResponse } from "../../../../core/services/auth/reset-password/reset-password-response";
import { ResetPasswordRequest } from "../../../../core/services/auth/reset-password/reset-password-request";

@Component({
  selector: "app-reset-password",
  standalone: true,
  templateUrl: "./reset-password.component.html",
  styleUrl: "./reset-password.component.scss",
  imports: [OutlineTextInputComponent, ButtonComponent, ReactiveFormsModule],
})
export class ResetPasswordComponent extends BaseComponent implements OnInit {
  @Input() token?: string;

  authService = inject(AuthService);
  formBuilder = inject(FormBuilder);

  resetPasswordForm!: FormGroup;

  resetPasswordState = new State<ResetPasswordResponse>();

  ngOnInit(): void {
    this.resetPasswordForm = this.formBuilder.group({
      password: ["", Validators.required],
      confirmPassword: ["", Validators.required],
    });
  }

  onLoginClicked() {
    this.router.navigate(["/login"]);
  }

  onResetPasswordClicked() {
    if (this.resetPasswordForm.invalid) {
      return;
    }

    let formData = this.resetPasswordForm.value;
    let request: ResetPasswordRequest = {
      token: this.token,
      password: formData.password,
    };

    this.executeRequest<ResetPasswordResponse>({
      state: this.resetPasswordState,
      request: this.authService.resetPassword(request),
      onSuccess: (response) => {
        this.resetPasswordForm.reset();
        this.router.navigate(["/login"]);
      },
      handleSuccess: true,
      successMessage: "You have successfully reset password.",
    });
  }
}
