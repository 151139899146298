import {Component, inject, OnInit} from '@angular/core';
import {AppSvgIconComponent} from "../../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {BaseTableComponent} from "../../../../../shared/components/_base/base-table/base-table.component";
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {MatMenu, MatMenuItem} from "@angular/material/menu";
import {
  OutlineTextInputComponent
} from "../../../../../shared/inputs/outline-text-input/outline-text-input/outline-text-input.component";
import {SortableTableDirective} from "../../../../../shared/components/_base/base-table/sortable-table.directive";
import {
  TableResizableColumnsDirective
} from "../../../../../shared/components/_base/base-table/table-resizable-columns.directive";
import {
  ExpiringDocumentsCardComponent
} from "../../../../dashboard/pages/expiring-documents-card/expiring-documents-card.component";
import {OverviewCardComponent} from "../../../../dashboard/pages/overview-card/overview-card.component";
import {FileUploaderComponent} from "../../../../../shared/inputs/file-uploader/file-uploader.component";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {RadioButtonComponent} from "../../../../../shared/inputs/radio-button/radio-button.component";
import {BaseComponent} from "../../../../../shared/base/base-component";
import {CheckboxComponent} from "../../../../../shared/inputs/checkbox/checkbox.component";

@Component({
  selector: 'app-driver-hiring',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    BaseTableComponent,
    ButtonComponent,
    MatMenu,
    MatMenuItem,
    OutlineTextInputComponent,
    SortableTableDirective,
    TableResizableColumnsDirective,
    ExpiringDocumentsCardComponent,
    OverviewCardComponent,
    FileUploaderComponent,
    FormsModule,
    ReactiveFormsModule,
    RadioButtonComponent,
    CheckboxComponent
  ],
  templateUrl: './driver-hiring.component.html',
  styleUrl: './driver-hiring.component.scss'
})
export class DriverHiringComponent extends BaseComponent implements OnInit{
  formBuilder = inject(FormBuilder);
  driverHiringForm! : FormGroup;
  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.driverHiringForm = this.formBuilder.group({
      clearingHouseQuery: ["", Validators.required],
      drugTestResultAttachment: ["", Validators.required],
      drugTestResult:[false, Validators.required],
      startSapProgram: [false,Validators.required],
      confirmationOfRequirements: ["", Validators.required],

    });
  }

  onStartProficiencyTest()
  {
    this.router.navigate(['/proficiency-test'])
  }


}
