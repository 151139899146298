<div class="flex justify-center container mx-auto h-full w-full p-4">
  <div class="flex flex-col bg-white w-full rounded-md px-4">
    <div class="grow overflow-auto p-4">
      <div class="flex flex-col gap-3">
        <p class="text-h6 text-info-500">Employment History</p>
        <p class="text-caption text-neutral-500">Please provide us all your employment records for past 3 years</p>
        <div class="flex border border-neutral-200 p-2">
          <div class="flex-shrink-0 w-16 flex items-center justify-center">
            <app-svg-icon src="assets/icons/info.svg" [size]="18" class="text-neutral-200"></app-svg-icon>
          </div>
          <div class="flex-1 flex flex-col gap-2">
            <p class="text-neutral-500 text-caption">
              The Federal Motor Carrier Safety Regulations (49 CFR 391.21) require that all applicants wishing to drive
              a commercial vehicle list all employment for the last three (3) years. In addition, if you have driven a
              commercial vehicle previously, you must provide employment history for an additional seven (7) years (for
              a total of ten (10) years). Any gaps in employment in excess of one (1) month must be explained.
            </p>
            <p class="text-neutral-500 text-caption">
              Start with the last or current position, including any military experience, and work backwards (attach
              separate sheets if necessary). You are required to list the complete mailing address, including street
              number, city, state, zip; and complete all other information.
            </p>
          </div>
        </div>
        <form [formGroup]="employmentForm">
          <div class="flex flex-col gap-3" formGroupName="employments">
            @if (currentOrRecentEmploymentForm) {
              <div [formGroup]="currentOrRecentEmploymentForm" class="employment-form flex flex-col gap-3 my-4">
                <span class="text-body2 text-info-500">Current or Most Recent Employer</span>
                <div class="driving-experience-form flex flex-col gap-3 my-4">
                  <div class="grid lg:grid-cols-2 gap-4">
                    <app-outline-text-input formControlName="name" [label]="'Employer Name'" placeholder="Ex: John"
                                            class="w-full" [fullWidth]="true"></app-outline-text-input>
                    <app-outline-text-input formControlName="phone" [label]="'Phone'" placeholder="Ex: +44 7868866696"
                                            class="w-full" [fullWidth]="true"></app-outline-text-input>
                  </div>
                  <div class="grid grid-cols-2 gap-4">
                    <app-outline-text-input formControlName="address" [label]="'Address'"
                                            placeholder="Ex: 123 Main St, Apt 4B, Springfield, IL 62704" class="w-full"
                                            [fullWidth]="true"></app-outline-text-input>
                    <app-outline-text-input formControlName="state" [label]="'State'" placeholder="Ex: California"
                                            class="w-full" [fullWidth]="true"></app-outline-text-input>
                  </div>
                  <div class="grid grid-cols-2 gap-4">
                    <app-outline-text-input formControlName="positionHeld" [label]="'Position Held'"
                                            placeholder="Driver" class="w-full"
                                            [fullWidth]="true"></app-outline-text-input>
                  </div>
                  <div class="grid grid-cols-2 gap-4">
                    <app-outline-text-input formControlName="jobJoinDate" [label]="'Job Stared Date'"
                                            placeholder="Ex: 10/24/2022" mask="00/00/0000" class="w-full"
                                            [fullWidth]="true"></app-outline-text-input>
                    <app-outline-text-input formControlName="jobEndDate" [label]="'Job End Date'"
                                            placeholder="Ex: 10/24/2024" mask="00/00/0000" class="w-full"
                                            [fullWidth]="true"></app-outline-text-input>
                  </div>
                  <div class="grid lg:grid-cols-2 gap-4">
                    <app-outline-text-input formControlName="leavingReason" [label]="'Reason for Leaving'"
                                            placeholder="Ex: Relocating to a new city" class="w-full"
                                            [fullWidth]="true"></app-outline-text-input>
                    <app-outline-text-input formControlName="salary" type="number" [label]="'Salary (Monthly)'"
                                            placeholder="Ex: 5000" class="w-full"
                                            [fullWidth]="true"></app-outline-text-input>
                  </div>
                  <div class="flex items-center gap-3 w-full">
                    <app-outline-text-input formControlName="gapsExplain" [label]="'Explain any gap in employment'"
                                            class="w-full" [fullWidth]="true"></app-outline-text-input>
                  </div>
                  <div class="flex items-center gap-3 max-w-4xl">
                    <span class="text-body2">While employed here, were you subject to the Federal Motor Carrier Safety Regulations (Did you drive a commercial vehicle)?</span>
                    <app-radio-button formControlName="fmcsrCompliance" title="Yes" groupName="fmcsrCompliance"
                                      [value]="true"></app-radio-button>
                    <app-radio-button formControlName="fmcsrCompliance" title="No" groupName="fmcsrCompliance"
                                      [value]="false"></app-radio-button>
                  </div>
                  <div class="flex items-center gap-3 max-w-4xl">
                                  <span class="text-body2">
                                      Was the job designated as a safety-sensitive function in any Department of Transportation-regulated mode subject to alcohol and controlled substances testing as required by 49 CFR, part 40?
                                  </span>
                    <app-radio-button formControlName="safetySensitiveTesting"
                                      groupName="safetySensitiveTestingCategory" title="Yes"
                                      [value]="true"></app-radio-button>
                    <app-radio-button formControlName="safetySensitiveTesting"
                                      groupName="safetySensitiveTestingCategory" title="No"
                                      [value]="false"></app-radio-button>
                  </div>
                </div>
                <div class="w-full h-[1px] bg-neutral-100 my-8"></div>
              </div>
            }
            @for (group of employmentsFormArray.controls; track group; let i = $index; let isLast = $last; let isFirst = $first) {
              @if (i > 0) {
                <div class="employment-form flex flex-col gap-3 my-4" [formGroupName]="i">
                  <span class="text-body2 text-info-500">ADDITIONAL EMPLOYMENT DETAILS</span>
                  <div class="grid lg:grid-cols-2 gap-4">
                    <app-outline-text-input formControlName="name" [label]="'Name'" placeholder="Ex: John"
                                            class="w-full" [fullWidth]="true"></app-outline-text-input>
                    <app-outline-text-input formControlName="phone" [label]="'Phone'" placeholder="Ex: +44 7868866696"
                                            class="w-full" [fullWidth]="true"></app-outline-text-input>
                  </div>
                  <div class="grid grid-cols-2 gap-4">
                    <app-outline-text-input formControlName="address" [label]="'Address'"
                                            placeholder="Ex: Forfeited bond, collateral points" class="w-full"
                                            [fullWidth]="true"></app-outline-text-input>
                    <app-outline-text-input formControlName="state" [label]="'State'" placeholder="Ex: California"
                                            class="w-full" [fullWidth]="true"></app-outline-text-input>
                  </div>
                  <div class="grid grid-cols-2 gap-4">
                    <app-outline-text-input formControlName="positionHeld" [label]="'Position Held'"
                                            placeholder="Driver" class="w-full"
                                            [fullWidth]="true"></app-outline-text-input>
                  </div>
                  <div class="grid grid-cols-2 gap-4">
                    <app-outline-text-input formControlName="jobJoinDate" [label]="'Job Stared Date'"
                                            placeholder="Ex: 10/24/2022" mask="00/00/0000" class="w-full"
                                            [fullWidth]="true"></app-outline-text-input>
                    <app-outline-text-input formControlName="jobEndDate" [label]="'Job End Date'"
                                            placeholder="Ex: 10/24/2024" mask="00/00/0000" class="w-full"
                                            [fullWidth]="true"></app-outline-text-input>
                  </div>
                  <div class="grid lg:grid-cols-2 gap-4">
                    <app-outline-text-input formControlName="leavingReason" [label]="'Reason for Leaving'"
                                            placeholder="Ex: Relocating to a new city" class="w-full"
                                            [fullWidth]="true"></app-outline-text-input>
                    <app-outline-text-input formControlName="salary" type="number" [label]="'Salary (Monthly)'"
                                            placeholder="Ex: 5000" class="w-full"
                                            [fullWidth]="true"></app-outline-text-input>
                  </div>
                  <div class="flex items-center gap-3 w-full">
                    <app-outline-text-input formControlName="gapsExplain" [label]="'Explain any gap in employment'"
                                            class="w-full" [fullWidth]="true"></app-outline-text-input>
                  </div>
                  <div class="flex items-center gap-3 max-w-4xl">
                    <span class="text-body2">While employed here, were you subject to the Federal Motor Carrier Safety Regulations (Did you drive a commercial vehicle)?</span>
                    <app-radio-button formControlName="fmcsrCompliance" title="Yes" [groupName]="'fmcsrCompliance' + i"
                                      [value]="true"></app-radio-button>
                    <app-radio-button formControlName="fmcsrCompliance" title="No" [groupName]="'fmcsrCompliance' + i"
                                      [value]="false"></app-radio-button>
                  </div>
                  <div class="flex items-center gap-3 max-w-4xl">
                                <span class="text-body2">
                                    Was the job designated as a safety-sensitive function in any Department of Transportation-regulated mode subject to alcohol and controlled substances testing as required by 49 CFR, part 40?
                                </span>
                    <app-radio-button formControlName="safetySensitiveTesting" [groupName]="'safetySensitiveTesting' + i"
                                      title="Yes" [value]="true"></app-radio-button>
                    <app-radio-button formControlName="safetySensitiveTesting" [groupName]="'safetySensitiveTesting' + i"
                                      title="No" [value]="false"></app-radio-button>
                  </div>
                  @if (!isFirst) {
                    <div class="flex justify-end">
                      <app-button appearance="outline" outlineColor="border-error-500" textButtonColor="text-error-500"
                                  (click)="onDeleteClicked(i)">Delete
                      </app-button>
                    </div>
                  }
                </div>
                @if (!isLast) {
                  <div class="w-full h-[1px] bg-neutral-100 my-8"></div>
                }
              }
            }
            <div class="flex flex-col gap-4 my-3">
              <app-button appearance="outline" [fullWidth]="true" (click)="onAddMoreEmploymentsClicked()">ADD MORE
                EMPLOYMENT
              </app-button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="flex justify-between border-t border-neutral-200 px-4 py-4">
      <app-button appearance="outline" (click)="onPreviousClicked()">Previous</app-button>
      <app-button type="submit" (click)="onSaveAndNextClicked()" [loading]="saveEmploymentState.loading()">Save & Next
      </app-button>
    </div>
  </div>
</div>
